/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useEffect, useState } from "react";
import Loading from "../components/Loading";
import { useLocation } from "react-router-dom";
import ImageGallery from "react-image-gallery";
import { unVerifyUser, verifyUser } from "../actions/user.actions";
import { useDispatch } from "react-redux";
import ActivityIndicator from "../components/ActivityIndicator";
import axios from "axios";
import { sendNotif } from "../Utils";
import Button from "../components/Button";
import { CheckCircle, Trash2 } from "react-feather";

const VerifyUser = () => {
  let location = useLocation();
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(true);
  const [verifyPending, setVerifyPending] = useState(false);
  const [deletePending, setDetelePending] = useState(false);

  const [verifyData, setVerifyData] = useState({});

  const handleVerifyFetch = async (id) => {
    await axios
      .get(`${process.env.REACT_APP_SERVER}/api/user/userverify/${id}`)
      .then((res) => {
        if (res.data.success) {
          setVerifyData(res.data);
          console.log(res.data);
          setLoading(false);
        } else {
          setVerifyData({});
          sendNotif(res.data.message, "error");
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  useEffect(() => {
    let { userId } = location.state;
    window.scrollTo({ top: 0, behavior: "smooth" });
    handleVerifyFetch(userId);
  }, [location.state]);

  const handleVerify = () => {
    dispatch(verifyUser(verifyData.infos.verifierId._id, setVerifyPending));
  };
  const handleUnverify = () => {
    dispatch(unVerifyUser(verifyData.infos.verifierId._id, setDetelePending));
  };

  if (loading) {
    return <Loading />;
  }
  return (
    <div className="page-content">
      <div className="card-container">
        <ImageGallery
          items={verifyData.Images.map((image) => ({
            original: `data:image/png;base64,${image}`,
            thumbnail: `data:image/png;base64,${image}`
          }))}
          showThumbnails={false}
        />
        <div className="info">
          <h4>
            Nom & Prénom(s): {verifyData.infos.verifierId.firstName}{" "}
            {verifyData.infos.verifierId.lastName}
          </h4>
          <p>Numéro de téléphone: {verifyData.infos.verifierId.phone}</p>
          <p>Infos complémentaires: {verifyData.infos.infoComplementaire}</p>
          <div className="section">
            {verifyPending ? (
              <ActivityIndicator />
            ) : (
              <Button
                value={"Valider le compte"}
                icon={<CheckCircle size={18} />}
                bg={"var(--first-color)"}
                onClick={handleVerify}
              />
            )}
            {deletePending ? (
              <ActivityIndicator />
            ) : (
              <Button
                value={"Ne pas valider le compte"}
                icon={<Trash2 size={18} />}
                bg={"var(--light-red)"}
                onClick={handleUnverify}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default VerifyUser;
