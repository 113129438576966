import React, { useEffect, useState } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import ActivityIndicator from "../components/ActivityIndicator";
import CashoutsCard from "../components/CashoutsCard";
import ToggleSwitch from "../components/ToggleSwitch";

const Cashouts = () => {
  const userData = useSelector((state) => state.userReducer);
  const [dataLoad, setDataLoad] = useState(false);
  const [error, setError] = useState(null);

  const [filterLists, setFilterLists] = useState([]);

  const [isSunShining, setIsSunShining] = useState(false);

  const handlecashoutsFetch = async () => {
    setDataLoad(true);
    await axios
      .get(`${process.env.REACT_APP_SERVER}/api/bonus/cashout`)
      .then((res) => {
        if (res.data.success) {
          if (isSunShining) {
            setFilterLists(
              res.data.bonus.filter((cashout) => cashout.isValidate)
            );
          } else {
            setFilterLists(
              res.data.bonus.filter((cashout) => !cashout.isValidate)
            );
          }
          setError(null);
        } else {
          setError(res.data.message);
        }
      })
      .catch((err) => {
        setFilterLists([]);
        console.log(err);
      });
    setDataLoad(false);
  };

  useEffect(() => {
    document.title = "Demandes de cashout";
    window.scrollTo({ top: 0, behavior: "smooth" });
    handlecashoutsFetch();
  }, [userData, isSunShining]);

  const onChange = (newValue) => {
    setIsSunShining(newValue);
  };

  return (
    <div className="page-content">
      <div className="transaction-container">
        <input
          type="button"
          className="refresh"
          value="↻"
          onClick={handlecashoutsFetch}
        />
        <ToggleSwitch
          name="status"
          checked={isSunShining}
          onChange={onChange}
        />
      </div>

      {dataLoad ? (
        <div className="refresh-container">
          <ActivityIndicator />
        </div>
      ) : (
        <div className="historique-container">
          {error ? (
            <div className="card error">{error}</div>
          ) : (
            <>
              {filterLists?.length > 0 ? (
                filterLists.map((cashout) => {
                  return (
                    <CashoutsCard
                      key={cashout._id}
                      cashout={cashout}
                      handlecashoutsFetch={handlecashoutsFetch}
                    />
                  );
                })
              ) : (
                <div className="card error">Pas de demande.</div>
              )}
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default Cashouts;
