import React, { useEffect, useState } from "react";
import { UidContext } from "./components/AppContext";
import { useDispatch } from "react-redux";
import { getUser } from "./actions/user.actions";
import { getAmbassador } from "./actions/ambassador.actions";
import { getCurrency } from "./actions/currency.actions";
import Loading from "./components/Loading";
import { getUsers } from "./actions/users.actions";
import { getComments } from "./actions/comments.actions";
import { getBonus } from "./actions/bonus.actions";
import { getFaq } from "./actions/faq.actions";
import { ToastContainer } from "react-toastify";
import { NotificationReceive, Resquest } from "./Utils";
import Router from "./Router";
import PopupPub from "./components/PopupPub";
import { getPub } from "./actions/pub.actions";
import { getExchanges } from "./actions/exchanges.actions";

function App() {
  const [uid, setUid] = useState(null);

  const [loading, setLoading] = useState(true);

  const dispatch = useDispatch();

  useEffect(() => {
    const fecthUser = async () => {
      await Resquest.get(`${process.env.REACT_APP_SERVER}/api/user/auth`)
        .then((res) => {
          if (res.data.success) {
            dispatch(getUser(res.data.user));
            setUid(res.data.user);
            setLoading(false);
          } else {
            setLoading(false);
          }
        })
        .catch((err) => {
          console.log("No token in frontend");
          setLoading(false);
        });
    };
    fecthUser();
  }, [dispatch]);

  useEffect(() => {
    dispatch(getCurrency());
    dispatch(getComments());
    dispatch(getBonus());
    dispatch(getUsers());
    dispatch(getExchanges());
    dispatch(getAmbassador());
    dispatch(getFaq());
    dispatch(getPub());
  }, [dispatch]);

  useEffect(() => {
    if (uid) {
      NotificationReceive(uid);
    }
  }, [uid]);

  if (loading) {
    return <Loading />;
  }
  return (
    <UidContext.Provider value={uid}>
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <Router />
      <PopupPub />
    </UidContext.Provider>
  );
}

export default App;
