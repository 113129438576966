import React, { useState, useEffect, useContext } from "react";
import Images from "../constantes/images";
import { NavLink } from "react-router-dom";
import { UidContext } from "../components/AppContext";
import { useSelector } from "react-redux";
import { Home, Info, HelpCircle, UserPlus, User } from "react-feather";
import useScrollPosition from "../components/useScrollPosition";

const Nav = () => {
  const userData = useSelector((state) => state.userReducer);
  const [windowDimension, setWindowDimension] = useState(null);
  const isMobile = windowDimension <= 600;
  const scrollPosition = useScrollPosition();

  const uid = useContext(UidContext);

  //nav dimension
  useEffect(() => {
    setWindowDimension(window.innerWidth);
  }, []);
  useEffect(() => {
    function handleResize() {
      setWindowDimension(window.innerWidth);
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  //nav dimension

  return (
    <nav
      className={
        isMobile
          ? "navbarBottom"
          : scrollPosition > 100
          ? "navbarTop shadow"
          : "navbarTop"
      }
    >
      {!isMobile && (
        <NavLink to="/" className="nav-logo">
          <img src={Images.logo} alt="logo" />
        </NavLink>
      )}
      <ul className="nav-links" id="navbar">
        <li className="nav-item">
          <NavLink
            exact={`${true}`}
            to="/"
            className={(navData) =>
              navData.isActive ? "nav-link active-link" : "nav-link"
            }
          >
            <span className="nav-icon">
              <Home size={18} />
            </span>
            <span className="nav-text">Accueil</span>
          </NavLink>
        </li>
        {!userData.isAdmin && (
          <li className="nav-item">
            <NavLink
              to="/a-propos"
              className={(navData) =>
                navData.isActive ? "nav-link active-link" : "nav-link"
              }
            >
              <span className="nav-icon">
                <Info size={18} />
              </span>
              <span className="nav-text">À propos</span>
            </NavLink>
          </li>
        )}

        {!userData.isAdmin && (
          <li className="nav-item">
            <NavLink
              to="/faq"
              className={(navData) =>
                navData.isActive ? "nav-link active-link" : "nav-link"
              }
            >
              <span className="nav-icon">
                <HelpCircle size={18} />
              </span>
              <span className="nav-text">Faq</span>
            </NavLink>
          </li>
        )}

        {!uid ? (
          <li className="nav-item">
            <NavLink
              to={userData.isAdmin ? "/dashboard" : "/profil"}
              className={(navData) =>
                navData.isActive ? "nav-link active-link" : "nav-link"
              }
            >
              <span className="nav-icon">
                <User size={18} />
              </span>
              <span className="nav-text">
                {userData.isAdmin ? "Dashboord" : "Profil"}
              </span>
            </NavLink>
          </li>
        ) : (
          <li className="nav-item">
            <NavLink
              to="/account"
              className={(navData) =>
                navData.isActive ? "nav-link active-link" : "nav-link"
              }
            >
              <span className="nav-icon">
                <UserPlus size={18} />
              </span>
              <span className="nav-text">Compte</span>
            </NavLink>
          </li>
        )}
      </ul>
    </nav>
  );
};
export default Nav;
