import React, { useEffect, useState } from "react";
import { Send } from "react-feather";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { sendVerification } from "../actions/user.actions";
import ActivityIndicator from "../components/ActivityIndicator";
import Button from "../components/Button";
import { isEmpty, sendNotif } from "../Utils";

const VerifyProfile = ({ close }) => {
  const userData = useSelector((state) => state.userReducer);
  const usersData = useSelector((state) => state.usersReducer);
  const error = useSelector((state) => state.errorReducer);

  const dispatch = useDispatch();
  useEffect(() => {
    document.title = "Vaancoin - Vérifier mon compte.";
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  const [typeDoc, setTypeDoc] = useState("");
  const [infoComplementaire, setInfoComplementaire] = useState("");
  const [file, setFile] = useState();

  const [sendPending, setSendPending] = useState(false);

  const options = [
    {
      value: "Carte Nationale d'Identité",
      label: "Carte Nationale d'Identité"
    },
    { value: "Permis de conduire", label: "Permis de conduire" },
    { value: "Passport", label: "Passport" },
    { value: "Attestation d'Identité", label: "Attestation d'Identité" }
  ];

  function upload(e) {
    let files = e.target.files;
    setFile(files);
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = new FormData();
    data.append("verifierId", userData._id);
    data.append("typeDoc", typeDoc);
    data.append("infoComplementaire", infoComplementaire);
    for (var i = 0; i < file.length; i++) {
      data.append("file", file[i]);
    }
    dispatch(
      sendVerification(
        data,
        setSendPending,
        close,
        usersData.filter((user) => user.isAdmin).map((data) => data._id)
      )
    );
  };

  if (userData.isVerifiedCredentials) {
    return (
      <div className="page-content">
        <p className="success">Votre compte est déjà vérifié.</p>
      </div>
    );
  }
  return (
    <form
      className="login-form"
      encType="multipart/form-data"
      action=""
      onSubmit={handleSubmit}
    >
      <h1>Vérifier votre compte</h1>
      <span className="close" onClick={() => close()}>
        &#x2715;
      </span>

      <p>Type de document:</p>
      <Select
        options={options}
        onChange={(e) => setTypeDoc(e.value)}
        placeholder={<div>Type de document</div>}
        className="login-input"
      />
      <span>Informations complémentaires:</span>
      <textarea
        className="comment-input"
        resize="none"
        onChange={(e) => {
          setInfoComplementaire(e.target.value);
        }}
      ></textarea>
      <span>Selectionnez les fichiers ici:</span>
      <input
        type="file"
        name="file"
        className="val-input"
        accept=".jpg, .jpeg, .png, pdf"
        onChange={upload}
        multiple
        required
      />
      {!isEmpty(error.format) && sendNotif(error.format, "error")}
      {!isEmpty(error.maxSize) && sendNotif(error.maxSize, "error")}
      {sendPending ? (
        <ActivityIndicator />
      ) : (
        <Button
          type="submit"
          value={"Envoyer la demande"}
          icon={<Send size={18} />}
          bg={"#1b37a7"}
        />
      )}
    </form>
  );
};

export default VerifyProfile;
