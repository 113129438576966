import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Popup from "reactjs-popup";
import AddFaq from "../components/AddFaq";
import UpdateFaq from "../components/UpdateFaq";
import { useDispatch } from "react-redux";
import { deleteFaq } from "../actions/faq.actions";
import { Edit, HelpCircle, Trash2 } from "react-feather";
import Button from "../components/Button";

const Faq = () => {
  const dispatch = useDispatch();

  const [isReveal, setIsReveal] = useState(null);

  const faqData = useSelector((state) => state.faqReducer);
  const userData = useSelector((state) => state.userReducer);

  useEffect(() => {
    document.title = "Vaancoin - Foire aux questions";
    document.description = "Trouver la réponse à votre question.";
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  const handleDelete = (faqId) => {
    const accord = window.confirm("Voulez-vous vraiment le supprimer ?");
    if (accord) {
      dispatch(deleteFaq(faqId));
    }
  };

  return (
    <div className="page-content">
      <div className="faq-container">
        <h3>Foire aux questions</h3>

        {userData.isAdmin ? (
          <Popup
            modal={true}
            nested
            trigger={
              <Button
                value={"Ajouter une FAQ"}
                icon={<HelpCircle size={18} />}
                bg={"var(--first-color)"}
              />
            }
            position="center"
          >
            {(close) => <AddFaq close={close} />}
          </Popup>
        ) : null}
        {faqData.length > 0 ? (
          faqData.map((faq, i) => {
            return (
              <span className="faq" key={i}>
                <span
                  className="accordion"
                  onClick={() => {
                    setIsReveal(i);
                    if (isReveal === i) setIsReveal(null);
                  }}
                >
                  {faq.question}
                  {userData.isAdmin ? (
                    <>
                      <Popup
                        modal={true}
                        nested
                        trigger={
                          <Button
                            value={"Modifier"}
                            icon={<Edit size={18} />}
                            bg={"var(--first-color)"}
                          />
                        }
                        position="center"
                      >
                        {(close) => <UpdateFaq data={faq} close={close} />}
                      </Popup>

                      <Button
                        value={"Supprimer"}
                        icon={<Trash2 size={18} />}
                        bg={"var(--light-red)"}
                        onClick={() => handleDelete(faq._id)}
                      />
                    </>
                  ) : null}
                  <span>{isReveal === i ? "-" : "+"}</span>
                </span>

                <div className={isReveal === i ? "panel reveal" : "panel"}>
                  <p>{faq.answer}</p>
                </div>
              </span>
            );
          })
        ) : (
          <p>Rien ici</p>
        )}
      </div>
    </div>
  );
};

export default Faq;
