import {
  GET_AMBASSADOR,
  UPDATE_AMBASSADOR,
  DELETE_AMBASSADOR
} from "../actions/ambassador.actions";

const initialState = [];

export default function ambassadorReducer(state = initialState, action) {
  switch (action.type) {
    case GET_AMBASSADOR:
      return action.payload;
    case UPDATE_AMBASSADOR:
      return state.map((ambassador) => {
        if (ambassador._id === action.payload.ambassadorId) {
          return {
            ...ambassador,
            name: action.payload.name,
            codeParrain: action.payload.codeParrain
          };
        } else return ambassador;
      });
    case DELETE_AMBASSADOR:
      return state.filter(
        (ambassador) => ambassador._id !== action.payload.ambassadorId
      );
    default:
      return state;
  }
}
