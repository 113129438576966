import { GET_FAQ, UPDATE_FAQ, DELETE_FAQ } from "../actions/faq.actions";

const initialState = [];

export default function faqReducer(state = initialState, action) {
  switch (action.type) {
    case GET_FAQ:
      return action.payload;
    case UPDATE_FAQ:
      return state.map((faq) => {
        if (faq._id === action.payload.faqId) {
          return {
            ...faq,
            question: action.payload.question,
            answer: action.payload.answer
          };
        } else return faq;
      });
    case DELETE_FAQ:
      return state.filter((faq) => faq._id !== action.payload.faqId);
    default:
      return state;
  }
}
