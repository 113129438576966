import React, { useEffect, useState } from "react";
import Loading from "../components/Loading";
import axios from "axios";
import { useSelector } from "react-redux";
import CheckList from "../components/CheckList";
import Search from "../components/Search";

const Verify = () => {
  const userData = useSelector((state) => state.userReducer);
  const [loading, setLoading] = useState(true);
  const [verifyData, setVerifyData] = useState([]);
  const [error, setError] = useState(null);
  const [input, setInput] = useState("");
  const [filterLists, setFilterLists] = useState();

  const handleVerifyFetch = async () => {
    await axios
      .get(`${process.env.REACT_APP_SERVER}/api/user/userverify/all`)
      .then((res) => {
        if (res.data.success) {
          setVerifyData(res.data.verifyList);
          setError(null);
        } else {
          setError(res.data.message);
        }
      })
      .catch((err) => {
        setVerifyData([]);
        console.log(err);
      });
    return true;
  };

  const updateInput = async (input) => {
    const filtered = verifyData.filter((verify) => {
      return (
        verify.verifierId.firstName
          .toLowerCase()
          .includes(input.toLowerCase()) ||
        verify.verifierId.lastName.toLowerCase().includes(input.toLowerCase())
      );
    });
    setInput(input);
    setFilterLists(filtered);
  };

  useEffect(() => {
    document.title = "Les verifications";
    window.scrollTo({ top: 0, behavior: "smooth" });
    if (handleVerifyFetch()) {
      setLoading(false);
    }
  }, [userData]);
  if (loading) {
    return <Loading />;
  }
  return (
    <div className="page-content">
      {verifyData.length > 0 ? (
        <div className="verify-container">
          <Search input={input} setKeyword1={updateInput} />
          <h4>Les demandes de vérification de compte:</h4>

          {error ? (
            <div className="card error">{error}</div>
          ) : (
            <div className="verify-grid">
              {filterLists
                ? filterLists.map((verify) => {
                    return <CheckList data={verify} key={verify._id} />;
                  })
                : verifyData.map((verify) => {
                    return <CheckList data={verify} key={verify._id} />;
                  })}
            </div>
          )}
        </div>
      ) : (
        <div className="verify-container">
          {" "}
          <p>Pas de demande pour l'instant.</p>
        </div>
      )}
    </div>
  );
};

export default Verify;
