import axios from "axios";
import { Resquest, sendNotif } from "../Utils";

export const GET_AMBASSADOR = "GET_AMBASSADOR";
export const UPDATE_AMBASSADOR = "UPDATE_AMBASSADOR";
export const DELETE_AMBASSADOR = "DELETE_AMBASSADOR";

export const getAmbassador = () => {
  return (dispatch) => {
    return axios
      .get(`${process.env.REACT_APP_SERVER}/api/ambassador`)
      .then((res) => {
        dispatch({ type: GET_AMBASSADOR, payload: res.data });
      })
      .catch((err) => console.log(err));
  };
};

export const updateAmbassador = (
  ambassadorId,
  name,
  codeParrain,
  setPending,
  close
) => {
  return (dispatch) => {
    setPending(true);
    return Resquest.put(
      `${process.env.REACT_APP_SERVER}/api/ambassador/` + ambassadorId,
      { name, codeParrain }
    )
      .then((res) => {
        if (res.data.success) {
          setPending(false);
          sendNotif(res.data.message, "success");
          close();
          dispatch({
            type: UPDATE_AMBASSADOR,
            payload: {
              ambassadorId,
              name,
              codeParrain
            }
          });
        } else {
          sendNotif(res.data.message, "error");
          setPending(false);
        }
      })
      .catch((err) => {
        setPending(false);
        console.log(err);
      });
  };
};

export const deleteAmbassador = (ambassadorId) => {
  return (dispatch) => {
    return Resquest.delete(
      `${process.env.REACT_APP_SERVER}/api/ambassador/${ambassadorId}`
    )
      .then((res) => {
        if (res.data.success) {
          sendNotif(res.data.message, "success");
          dispatch({ type: DELETE_AMBASSADOR, payload: { ambassadorId } });
        } else {
          sendNotif(res.data.error, "error");
        }
      })
      .catch((err) => console.log(err));
  };
};
