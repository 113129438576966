import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { dateParser } from "../Utils";
import Popup from "reactjs-popup";
import Cashout from "../components/Cashout";
import Button from "../components/Button";
import { DollarSign } from "react-feather";

const Parrainage = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    document.title = "Vaancoin - Mes parrainages.";
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [dispatch]);

  const usersData = useSelector((state) => state.usersReducer);
  const userData = useSelector((state) => state.userReducer);
  const bonusData = useSelector((state) => state.bonusReducer);
  const [error, setError] = useState(null);

  return (
    <div className="page-content">
      <div className="user-container">
        <p>
          Vous recevez des points à chaque transaction éffectuée et validée par
          une personne que vous avez référencé.{" "}
          <span className="variable">
            {bonusData.point ? bonusData.point : 0} points ={" "}
            {bonusData.prix ? bonusData.prix : 0} $
          </span>
        </p>
        <span>
          Mininum retrait: {bonusData.minimum ? bonusData.minimum : 0} points
        </span>{" "}
        <br />
        <span>
          {" "}
          Point par transaction valide:{" "}
          {bonusData.pointParTransaction
            ? bonusData.pointParTransaction
            : 0}{" "}
          points{" "}
        </span>
        <br />
        <hr />
        <span>Mes points: {userData.bonus} </span>
        <span>
          / Solde: {(userData.bonus * bonusData.prix) / bonusData.point} $
        </span>
        <Popup
          modal={true}
          nested
          trigger={
            <Button
              value={"Demander le retrait"}
              icon={<DollarSign size={18} />}
              bg={"#1b37a7"}
            />
          }
          position="center"
        >
          {(close) => <Cashout close={close} setError={setError} />}
        </Popup>
        {error && <span className="error">{error}</span>}
        <div className="grid">
          {usersData.map((user) => {
            for (let i = 0; i < userData.referrers?.length; i++) {
              if (user._id === userData.referrers[i]) {
                return (
                  <div className="card2" key={user._id}>
                    <span>
                      {user.firstName} {user.lastName}
                    </span>
                    <p className="small">
                      Membre depuis: {dateParser(user.createdAt)}
                    </p>
                  </div>
                );
              }
            }
            return null;
          })}
        </div>
      </div>
    </div>
  );
};

export default Parrainage;
