import { GET_EXCHANGE } from "../actions/exchanges.actions";

const initialState = [];

export default function exchangesReducer(state = initialState, action) {
  switch (action.type) {
    case GET_EXCHANGE:
      return action.payload;
    default:
      return state;
  }
}
