import React, { useState } from "react";
import { Send } from "react-feather";
import { sendNotificationPush } from "../Utils";
import ActivityIndicator from "./ActivityIndicator";
import Button from "./Button";

const MessagePush = ({ close }) => {
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [image, setImage] = useState("");
  const [pending, setPending] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    await sendNotificationPush({
      title,
      description,
      image,
      setPending,
      close
    });
  };
  return (
    <form
      className="login-form"
      encType="multipart/form-data"
      action=""
      onSubmit={handleSubmit}
    >
      <h1>Notification</h1>
      <span className="close" onClick={() => close()}>
        &#x2715;
      </span>

      <label htmlFor="">
        <span>Titre: </span>{" "}
        <input
          type="text"
          className="login-input"
          onChange={(e) => setTitle(e.target.value)}
        />
      </label>
      <label htmlFor="">
        <span>Description: </span>{" "}
        <textarea
          className="comment-input"
          resize="none"
          onChange={(e) => {
            setDescription(e.target.value);
          }}
        ></textarea>
      </label>
      <label htmlFor="">
        <span>Lien image: </span>{" "}
        <input
          type="text"
          className="login-input"
          onChange={(e) => setImage(e.target.value)}
        />
      </label>
      {pending ? (
        <ActivityIndicator />
      ) : (
        <Button
          type="submit"
          value={"Envoyer le message"}
          icon={<Send size={18} />}
          bg={"#1b37a7"}
        />
      )}
    </form>
  );
};

export default MessagePush;
