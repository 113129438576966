import React from "react";
import ReactDOM from "react-dom/client";
import "./styles/theme.css";
import "./styles/Nav.css";
import "./styles/Home.css";
import "./styles/ExchengeForm.css";
import "./styles/Footer.css";
import "./styles/login.css";
import "./styles/Faq.css";
import "./styles/about.css";
import "./styles/Profile.css";
import "./styles/history.css";
import "./styles/Admin.css";
import "./styles/Verify.css";
import "./styles/Comment.css";
import "./styles/ToggleSwitch.css";
import "./styles/Pagination.css";
import "./styles/DarkMode.css";
import "./styles/Modal.css";
import "./styles/NotFound.css";
import "./styles/Transation.css";

import "font-awesome/css/font-awesome.min.css";

import App from "./App";

import { Provider } from "react-redux";
import { configureStore } from "@reduxjs/toolkit";
import rootReducer from "./reducers";

const store = configureStore({
  reducer: rootReducer,
  devTools: process.env.NODE_ENV !== "production"
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>
);
