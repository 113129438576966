import React, { useContext, useEffect, useState } from "react";
import { UidContext } from "../components/AppContext";
import Popup from "reactjs-popup";
import AddComment from "../components/AddComment";
import CommentCard from "../components/CommentCard";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import Pagination from "../components/Pagination";
import { MessageCircle, UserPlus } from "react-feather";
import Button from "../components/Button";

const Commentaires = () => {
  const [items, setItems] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    document.title = "Vaancoin - Commentaires et avis.";
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);
  const commentData = useSelector((state) => state.commentReducer);

  const uid = useContext(UidContext);

  return (
    <div className="page-content">
      <div className="comment-container">
        {!uid ? (
          <NavLink to="/account">
            <Button
              value={"Connectez-vous pour poster un commentaire"}
              icon={<UserPlus size={18} />}
              bg={"#1b30a7"}
            />
          </NavLink>
        ) : (
          <div className="section">
            <Popup
              modal={true}
              nested
              trigger={
                <Button
                  value={"Ajouer un commentaire"}
                  icon={<MessageCircle size={18} />}
                  bg={"#1b30a7"}
                />
              }
              position="center"
            >
              {(close) => <AddComment close={close} />}
            </Popup>

            {commentData.filter(
              (comment) => comment.userId && comment.userId._id === uid
            ).length > 0 ? (
              <div>
                <h3>Mes commentaires</h3>
                <div className="grid-wrap">
                  {commentData
                    .filter(
                      (comment) => comment.userId && comment.userId._id === uid
                    )
                    .map((comment, index) => {
                      return <CommentCard data={comment} key={comment._id} />;
                    })}
                </div>
              </div>
            ) : (
              <p>Pas de commentaires de ma part pour l'instant.</p>
            )}
            <hr />
          </div>
        )}
        <div className="section">
          {commentData?.length > 0 ? (
            <div>
              <h3>Commentaires & Avis</h3>
              <div className="grid-wrap">
                {items?.map((comment, index) => {
                  return <CommentCard data={comment} key={comment._id} />;
                })}
              </div>
              <Pagination
                data={commentData}
                totalCount={commentData.length}
                setItems={setItems}
                className="pagination-bar"
                currentPage={currentPage}
                pageSize={6}
                onPageChange={(page) => setCurrentPage(page)}
              />
            </div>
          ) : (
            <p>Pas de commentaires pour l'instant de la part des autres.</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default Commentaires;
