import React from "react";

const Search = ({ keyword, setKeyword1, placeholder }) => {
  return (
    <input
      key="random1"
      className="search-input"
      value={keyword}
      placeholder={placeholder ? placeholder : "Rechercher..."}
      onChange={(e) => setKeyword1(e.target.value)}
    />
  );
};

export default Search;
