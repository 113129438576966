import { SEND_ERRORS } from "../actions/user.actions";

const initialState = {};

export default function errorReducer(state = initialState, action) {
  switch (action.type) {
    case SEND_ERRORS:
      return action.playload;

    default:
      return state;
  }
}
