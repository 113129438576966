import React, { useState } from "react";
import ActivityIndicator from "./ActivityIndicator";
import { useDispatch } from "react-redux";
import { updateAmbassador } from "../actions/ambassador.actions";
import Button from "./Button";
import { CheckCircle } from "react-feather";

const UpdateAmbassador = ({ close, data }) => {
  const [name, setName] = useState("");
  const [codeParrain, setCodeParrain] = useState("");
  const [pending, setPending] = useState(false);

  const dispatch = useDispatch();

  const handleUpdateAmbassador = async (e) => {
    e.preventDefault();
    setPending(true);
    dispatch(
      updateAmbassador(
        data._id,
        name || data.name,
        codeParrain || data.codeParrain,
        setPending,
        close
      )
    );
  };

  return (
    <form onSubmit={handleUpdateAmbassador} className="login-form">
      <span className="close" onClick={() => close()}>
        &#x2715;
      </span>
      <h1>Modifier {data.name}</h1>

      <label htmlFor="">
        <span>Nom: </span>{" "}
        <input
          type="text"
          className="login-input"
          defaultValue={data.name}
          onChange={(e) => setName(e.target.value)}
        />
      </label>
      <label htmlFor="">
        <span>Code parrain: </span>{" "}
        <input
          type="text"
          className="login-input"
          defaultValue={data.codeParrain}
          onChange={(e) => setCodeParrain(e.target.value)}
        />
      </label>
      {pending ? (
        <ActivityIndicator />
      ) : (
        <Button
          type="submit"
          value={"Mettre à jour"}
          icon={<CheckCircle size={18} />}
          bg={"#1b37a7"}
        />
      )}
    </form>
  );
};

export default UpdateAmbassador;
