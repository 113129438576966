import React, { useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Loading from "../components/Loading";
import { deleteData, sendNotification, sendNotif } from "../Utils";
import axios from "axios";
import ActivityIndicator from "../components/ActivityIndicator";
import { fetchLocalData } from "../Utils";
import { UidContext } from "../components/AppContext";
import { useNavigate } from "react-router-dom";
import Button from "../components/Button";
import { CheckCircle, XCircle } from "react-feather";

const Validate = () => {
  let navigate = useNavigate();
  const userData = useSelector((state) => state.userReducer);
  const usersData = useSelector((state) => state.usersReducer);
  const [transaction, setTransaction] = useState({});
  const [load, setLoad] = useState(true);
  const [noData, setNoData] = useState(false);
  const [pending, setPending] = useState(false);

  const uid = useContext(UidContext);

  ///
  const [adresse, setAdresse] = useState("");
  const [ref, setRef] = useState("");
  const [resumData, setResumData] = useState();

  let data;

  useEffect(() => {
    document.title = "Vaancoin - Valider ma transaction.";
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
    fetchLocalData(data, setNoData, setTransaction, setLoad, setResumData);
  }, [data]);

  const handleExchange = async (e) => {
    e.preventDefault();

    setPending(true);
    await axios
      .post(`${process.env.REACT_APP_SERVER}/api/exchange`, {
        userId: userData._id,
        resume: JSON.parse(resumData),
        adresse,
        ref
      })
      .then((res) => {
        if (res.data.success) {
          setPending(false);
          sendNotif(res.data.message, "success");
          uid
            ? sendNotification(
                `Une demande de transaction de la part de ${userData.firstName} ${userData.lastName} avec la référence ${ref}.`,
                usersData.filter((user) => user.isAdmin).map((data) => data._id)
              )
            : sendNotification(
                `Une demande de transaction de la part d'un utilisateur non inscrit sur le site avec la référence ${ref}.`,
                usersData.filter((user) => user.isAdmin).map((data) => data._id)
              );
          sendNotif("Opérateur notifié.", "success");

          const isDelete = deleteData("Exchange");
          if (isDelete) {
            fetchLocalData(data, setNoData);
            setTimeout(() => {
              uid
                ? navigate("/history", { replace: true })
                : navigate("/", { replace: true });
            }, 3000);
          }
        } else if (!res.data.success) {
          setPending(false);
          sendNotif(res.data.error, "error");
        }
      })
      .catch((err) => {
        console.log(err);
        setPending(false);
      });
  };

  const handleSaveDelete = () => {
    const isDelete = deleteData("Exchange");
    if (isDelete) {
      fetchLocalData(data, setNoData);
      setTimeout(() => {
        window.location = "/";
      }, 2000);
    }
  };
  if (load) {
    return <Loading />;
  }
  return (
    <div className="page-content">
      <div className="transaction-container">
        {noData ? (
          <div className="bigcard">
            {" "}
            <span className="error">Oups pas de données</span>
          </div>
        ) : (
          <>
            <div className="bigcard">
              <span className="close" onClick={handleSaveDelete}>
                &#x2715;
              </span>
              <h1>Espace d'échange</h1>
              <p>
                Vous envoyez:{" "}
                <span className="variable">
                  {transaction.isGiven
                    ? transaction.toConvert
                    : transaction.result}{" "}
                  {transaction.fcurrency.devise} {transaction.fcurrency.name}
                </span>
              </p>

              <p>
                Vous recevez:{" "}
                <span className="variable">
                  {transaction.isGiven
                    ? transaction.result
                    : transaction.toConvert}{" "}
                  {transaction.tcurrency.devise} {transaction.tcurrency.name}
                </span>
              </p>
              <p>
                Envoyez{" "}
                <span className="variable">
                  {transaction.isGiven
                    ? transaction.toConvert
                    : transaction.result}{" "}
                  {transaction.fcurrency.devise} {transaction.fcurrency.name}
                </span>{" "}
                au{" "}
                <span className="variable">
                  {transaction.fcurrency.receiver}
                </span>{" "}
                et remplissez le formulaire ci-dessous.
              </p>
              <p>
                {" "}
                Assurez-vous d’avoir fait le dépôt pour pouvoir entrer le numéro
                de référence.
              </p>
              <form action="" className="confirm-form">
                <label htmlFor="">
                  <span>Adresse du receveur:</span>
                  <input
                    className="confirm-input"
                    type="text"
                    value={adresse}
                    onChange={(e) => setAdresse(e.target.value)}
                  />
                </label>
                <label htmlFor="">
                  <span>Numéro de référence de la transacion:</span>
                  <input
                    className="confirm-input"
                    type="text"
                    value={ref}
                    onChange={(e) => setRef(e.target.value)}
                  />
                </label>
                <div className="bottom">
                  {pending ? (
                    <ActivityIndicator />
                  ) : (
                    <Button
                      value={"Valider ma transaction"}
                      icon={<CheckCircle size={18} />}
                      bg={"#0a47a2"}
                      onClick={handleExchange}
                    />
                  )}
                </div>
              </form>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default Validate;
