import React, { useState } from "react";
import ActivityIndicator from "./ActivityIndicator";
import { useDispatch, useSelector } from "react-redux";
import Button from "./Button";
import { CheckCircle } from "react-feather";
import { _addPub } from "../actions/pub.actions";
import { isEmpty, sendNotif } from "../Utils";

const AddPub = ({ close }) => {
  const [file, setFile] = useState();
  const [lien, setLien] = useState("");
  const [pending, setPending] = useState(false);
  const error = useSelector((state) => state.errorReducer);

  const dispatch = useDispatch();

  function upload(e) {
    let file = e.target.files[0];
    setFile(file);
  }

  const handlePub = async (e) => {
    e.preventDefault();
    const data = new FormData();
    data.append("file", file);
    data.append("lien", lien);
    dispatch(_addPub(data, setPending, close));

    !isEmpty(error.format) && sendNotif(error.format, "error");

    !isEmpty(error.maxSize) && sendNotif(error.maxSize, "error");
  };

  return (
    <form onSubmit={handlePub} className="login-form">
      <span className="close" onClick={() => close()}>
        &#x2715;
      </span>
      <h1>Ajouter une Pub</h1>
      <label htmlFor="">
        <span>Selectionnez l'image ici: </span>{" "}
        <input
          type="file"
          name="file"
          className="val-input"
          accept=".jpg, .jpeg, .png, pdf"
          onChange={upload}
          required
        />
      </label>
      <label htmlFor="">
        <span>Un lien associé ? : </span>{" "}
        <input
          type="text"
          name="lien"
          className="val-input"
          onChange={(e) => setLien(e.target.value)}
          value={lien}
        />
      </label>

      {pending ? (
        <ActivityIndicator />
      ) : (
        <Button
          value={"Mettre en ligne"}
          icon={<CheckCircle size={18} />}
          bg={"#1b37a7"}
        />
      )}
    </form>
  );
};

export default AddPub;
