import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Table from "../components/Table";
import Popup from "reactjs-popup";
import Button from "../components/Button";
import { Delete, Edit, Users } from "react-feather";
import UpdateAmbassador from "../components/UpdateAmbassador";
import { deleteAmbassador } from "../actions/ambassador.actions";
import AddAmbassador from "../components/AddAmbassador";
const Ambassadeurs = () => {
  const dispatch = useDispatch();
  const ambassadorData = useSelector((state) => state.ambassadorReducer);

  useEffect(() => {
    document.title = "Les ambassadeurs";
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  const handleDelete = (id) => {
    const accord = window.confirm("Voulez-vous vraiment le supprimer ?");
    if (accord) {
      dispatch(deleteAmbassador(id));
    }
  };

  const columns = [
    {
      name: "name",
      displayName: "Nom",
      inputFilterable: true,
      sortable: true
    },

    {
      name: "codeParrain",
      displayName: "Code Parrain",
      inputFilterable: true,
      exactFilterable: true,
      sortable: true
    },
    {
      name: "referrers",
      displayName: "Total référé",
      inputFilterable: true,
      exactFilterable: true,
      sortable: true
    },
    {
      name: "actions",
      displayName: "Actions"
    }
  ];

  return (
    <div className="page-content">
      <div className="table-container">
        <Popup
          closeOnDocumentClick={false}
          modal
          trigger={() => (
            <Button
              value={"Ajouter un ambassadur"}
              icon={<Users size={18} />}
              bg={"#1b30a7"}
            />
          )}
          lockScroll
        >
          {(close) => <AddAmbassador close={close} />}
        </Popup>
        <h1>Les ambassadeurs</h1>

        <Table
          columns={columns}
          data={ambassadorData.map((data) => ({
            name: data.name,
            codeParrain: data.codeParrain,
            referrers: data.referrers.length,
            actions: (
              <span
                style={{
                  display: "flex",
                  width: "max-content"
                }}
              >
                <Popup
                  modal={true}
                  nested
                  trigger={
                    <Button
                      value={"Modifier"}
                      icon={<Edit size={18} />}
                      bg={"#1ba747be"}
                    />
                  }
                  position="center"
                >
                  {(close) => <UpdateAmbassador data={data} close={close} />}
                </Popup>

                <Button
                  value={"Supprimer"}
                  icon={<Delete size={18} />}
                  bg={"#a71b1bb0"}
                  onClick={() => handleDelete(data._id)}
                />
              </span>
            )
          }))}
        />
      </div>
    </div>
  );
};

export default Ambassadeurs;
