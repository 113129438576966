import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateAdminStatus } from "../actions/user.actions";

const IsOnline = () => {
  const userData = useSelector((state) => state.userReducer);
  const usersData = useSelector((state) => state.usersReducer);
  const dispatch = useDispatch();

  return (
    <div className="toggle-theme-wrapper">
      <p className="status offline"></p>
      <label className="toggle-theme" htmlFor="isOnline">
        <input
          type="checkbox"
          id="isOnline"
          // 6
          onChange={(e) =>
            dispatch(
              updateAdminStatus(
                userData._id,
                e.target.checked,
                usersData
                  .filter((user) => !user.isAdmin)
                  .map((data) => data._id)
              )
            )
          }
          defaultChecked={userData.isOnline}
        />
        <div className="slider round"></div>
      </label>
      <p className="status online"></p>
    </div>
  );
};

export default IsOnline;
