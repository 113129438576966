import React from "react";
import FilterableTable from "react-filterable-table";

const Table = ({ columns, data }) => {
  return (
    <FilterableTable
      namespace="Users"
      initialSort="firstName"
      data={data}
      fields={columns}
      noRecordsMessage="Rien à afficher"
      noFilteredRecordsMessage="Aucun resultat!"
      recordCountName="Utilisateur"
      recordCountNamePlural="Utilisateurs"
      pagerTitles={{ first: "<|", last: ">|" }}
      topPagerVisible={false}
      pagerBottomClassName={"pagination-bar"}
      pageSize={20}
      pageSizes={[10, 30, 50, 100]}
    />
  );
};

export default Table;
