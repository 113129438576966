import React, { useEffect } from "react";

const Conditions = () => {
  useEffect(() => {
    document.title = "Vaancoin - Conditions";
    document.title = "Nos Conditions d'utilisation.";
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  return (
    <div className="page-content">
      <div className="about-container">
        <h1>Nos Conditions </h1>
      </div>
    </div>
  );
};

export default Conditions;
