import React from "react";

const ReserveCard = ({ data }) => {
  return (
    <div className="card">
      <div className="top">
        <img src={data.image} alt="" /> <p>{data.name}</p>
      </div>
      <div className="bottom">
        <p>
          {data.reserve} {data.devise}
        </p>
      </div>
    </div>
  );
};

export default ReserveCard;
