import React, { useState } from "react";
import ActivityIndicator from "../components/ActivityIndicator";
import { sendNotif } from "../Utils";
import axios from "axios";
import Button from "../components/Button";
import { CheckCircle } from "react-feather";

const ResetPassword = () => {
  const [sendPending, setSendPending] = useState(false);
  const [email, setEmail] = useState(null);

  const handleSendReset = (e) => {
    e.preventDefault();
    setSendPending(true);

    const resetOptions = {
      method: "post",
      url: `${process.env.REACT_APP_SERVER}/api/user/forgotPassword`,
      withCredentials: true,
      data: {
        email
      }
    };
    axios(resetOptions)
      .then((res) => {
        if (!res.data.success) {
          setSendPending(false);
          sendNotif(res.data.message, "error");
        } else {
          setSendPending(false);
          sendNotif(res.data.message, "success");
          setTimeout(() => {
            window.location = "/";
          }, 4000);
        }
      })
      .catch((err) => {
        setSendPending(false);
        sendNotif("Une erreur s'est produite, éssayez plus tard", "error");
        console.log(err);
      });
  };

  return (
    <div className="page-content">
      <div className="form-container">
        <form className="login-form" onSubmit={handleSendReset}>
          <h1>Entrez votre email</h1>
          <label htmlFor="">
            <input
              className="login-input"
              placeholder="E-mail"
              type="email"
              name="email"
              onChange={(e) => setEmail(e.target.value)}
            />
          </label>
          {sendPending ? (
            <ActivityIndicator />
          ) : (
            <Button
              value={"Confirmer"}
              type={"submit"}
              icon={<CheckCircle size={18} />}
              bg={"#0a47a2"}
            />
          )}
        </form>
      </div>
    </div>
  );
};

export default ResetPassword;
