/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import AdminReserveCard from "../components/AdminReserveCard";
import AddMonnaie from "../components/AddMonnaie";
import { NavLink } from "react-router-dom";
import { addBonus } from "../actions/bonus.actions";
import AddFaq from "../components/AddFaq";
import AddAmbassador from "../components/AddAmbassador";
import IsOnline from "../components/IsOnline";
import {
  getNewTransactionCount,
  getNewVerificationCount,
  getNewBonusCount,
  handleLogout,
  datesArray,
  dateParser2
} from "../Utils";

import "react-toastify/dist/ReactToastify.min.css";
import {
  DollarSign,
  HelpCircle,
  MessageCircle,
  Unlock,
  Users,
  Edit2,
  CheckCircle,
  XCircle,
  Image,
  Delete,
  Eye,
  EyeOff,
  LogOut,
  Bell
} from "react-feather";
import Button from "../components/Button";
import AddPub from "../components/AddPub";
import MessagePush from "../components/MessagePush";
import Popup from "reactjs-popup";
import { _deletePub } from "../actions/pub.actions";
import ActivityIndicator from "../components/ActivityIndicator";
import RNChart from "../components/RNChart";
import moment from "moment";
import SwitchSelector from "react-switch-selector";
moment.locale("fr");

const Dash = () => {
  const usersData = useSelector((state) => state.usersReducer);
  const exchangesData = useSelector((state) => state.exchangesReducer);
  const ambassadorsData = useSelector((state) => state.ambassadorReducer);
  const bonusData = useSelector((state) => state.bonusReducer);
  const commentData = useSelector((state) => state.commentReducer);
  const faqData = useSelector((state) => state.faqReducer);
  const pubData = useSelector((state) => state.pubReducer);

  const currencyData = useSelector((state) => state.currencyReducer);
  const [point, setPoint] = useState("");
  const [prix, setPrix] = useState("");
  const [minimum, setMinimum] = useState("");

  const [newTransaction, setNewTransaction] = useState(null);
  const [newVerify, setNewVerify] = useState(null);
  const [newBonus, setNewBonus] = useState(null);

  const [pointParTransaction, setPointParTransaction] = useState("");

  const [pubShow, setPubShow] = useState(false);
  const [deletePending, setDeletePending] = useState(false);
  const [isBar, setIsBar] = useState(false);
  const UserRegisterChartData = datesArray(
    moment().add(-5, "days"),
    moment().add(4, "days"),
    1
  ).map(function (date, i) {
    return {
      date: date,
      "Personnes inscrites": usersData.filter(
        (user) => dateParser2(user.createdAt) === date
      ).length
    };
  });

  const ExchangeChartData = datesArray(
    moment().add(-5, "days"),
    moment().add(4, "days"),
    1
  ).map(function (date, i) {
    return {
      date: date,
      "Demandes de transaction": exchangesData.filter(
        (exchange) => dateParser2(exchange.createdAt) === date
      ).length
    };
  });

  const switchOptions = [
    {
      label: "Ligne",
      value: false,
      selectedBackgroundColor: "#0c50b7"
    },
    {
      label: "Barre",
      value: true,
      selectedBackgroundColor: "#0c50b7"
    }
  ];
  useEffect(() => {
    document.title = "Administration";
    window.scrollTo({ top: 0, behavior: "smooth" });
    getNewTransactionCount(setNewTransaction);
    getNewVerificationCount(setNewVerify);
    getNewBonusCount(setNewBonus);
  }, []);

  const dispatch = useDispatch();

  return (
    <div className="page-content">
      <div className="admin-container">
        <div className="bgWhite grid">
          <NavLink to="/dashboard/transactions" className="nav-link">
            <span className="nav-icon">
              <DollarSign size={18} />
            </span>
            <span className="nav-text">
              Demandes de transactions{" "}
              <span className="notif-icon">{newTransaction}</span>
            </span>
          </NavLink>
          <NavLink to="/dashboard/cashout" className="nav-link">
            <span className="nav-icon">
              <DollarSign size={18} />
            </span>
            <span className="nav-text">
              Demandes de retrait bonus{" "}
              <span className="notif-icon">{newBonus}</span>
            </span>
          </NavLink>
          <NavLink to="/dashboard/verifications" className="nav-link">
            <span className="nav-icon">
              <Unlock size={18} />
            </span>
            <span className="nav-text">
              Demandes de vérification{" "}
              <span className="notif-icon">{newVerify}</span>
            </span>
          </NavLink>
          <NavLink to="/dashboard/users" className="nav-link">
            <span className="nav-icon">
              <Users size={18} />
            </span>
            <span className="nav-text">
              Liste des utilisateurs:{" "}
              <span className="notif-icon">
                {usersData.length -
                  usersData.filter((user) => user.isAdmin).length}
              </span>
            </span>
          </NavLink>
          <NavLink to="/dashboard/ambassadeurs" className="nav-link">
            <span className="nav-icon">
              <Users size={18} />
            </span>
            <span className="nav-text">
              Liste des ambassadeurs:{" "}
              <span className="notif-icon">{ambassadorsData.length}</span>
            </span>
          </NavLink>
          <NavLink to="/commentaires" className="nav-link">
            <span className="nav-icon">
              <MessageCircle size={18} />
            </span>
            <span className="nav-text">
              Les commentaires:{" "}
              <span className="notif-icon">{commentData.length}</span>
            </span>
          </NavLink>
          <NavLink to="/faq" className="nav-link">
            <span className="nav-icon">
              <HelpCircle size={18} />
            </span>
            <span className="nav-text">
              Total faq: <span className="notif-icon">{faqData.length}</span>
            </span>
          </NavLink>

          <IsOnline />
          <div className="nav-link">
            <span onClick={handleLogout} className="nav-link">
              <span className="nav-icon">
                <LogOut size={18} />
              </span>
              <span className="nav-text">Déconnexion</span>
            </span>
          </div>
        </div>

        <div className="row-admin">
          <h2>Pub</h2>{" "}
          <div className="grid">
            {pubData.success && (
              <>
                {" "}
                <Popup
                  closeOnDocumentClick={false}
                  modal
                  trigger={() => (
                    <Button
                      icon={pubShow ? <EyeOff size={18} /> : <Eye size={18} />}
                      bg={"#1b30a7"}
                      onClick={() => setPubShow(!pubShow)}
                    />
                  )}
                  lockScroll
                >
                  {(close) => (
                    <div className="login-form">
                      <span className="close" onClick={() => close()}>
                        &#x2715;
                      </span>
                      <img
                        src={`data:image/png;base64,${pubData.image}`}
                        style={{ height: "100%" }}
                      />
                    </div>
                  )}
                </Popup>
                {deletePending ? (
                  <ActivityIndicator />
                ) : (
                  <Button
                    value={"Supprimer"}
                    icon={<Delete size={18} />}
                    bg={"#ea2f3bd2"}
                    onClick={() => {
                      const agree = window.confirm(
                        "Voulez-vous vraiment supprimer la pub ?"
                      );
                      if (agree) {
                        dispatch(_deletePub(setDeletePending));
                      }
                    }}
                  />
                )}
              </>
            )}
            <Popup
              closeOnDocumentClick={false}
              modal
              trigger={() => (
                <Button
                  value={"Ajouter une Pub"}
                  icon={<Image size={18} />}
                  bg={"#1b30a7"}
                />
              )}
              lockScroll
            >
              {(close) => <AddPub close={close} />}
            </Popup>
          </div>
        </div>

        <div className="row-admin">
          <div className="grid">
            <Popup
              closeOnDocumentClick={false}
              modal
              trigger={() => (
                <Button
                  value={"Ajouter une monnaie"}
                  icon={<DollarSign size={18} />}
                  bg={"#1b30a7"}
                />
              )}
              lockScroll
            >
              {(close) => <AddMonnaie close={close} />}
            </Popup>
            <Popup
              closeOnDocumentClick={false}
              modal
              trigger={() => (
                <Button
                  value={"Ajouter une FAQ"}
                  icon={<HelpCircle size={18} />}
                  bg={"#1b30a7"}
                />
              )}
              lockScroll
            >
              {(close) => <AddFaq close={close} />}
            </Popup>
            <Popup
              closeOnDocumentClick={false}
              modal
              trigger={() => (
                <Button
                  value={"Ajouter un ambassadeur"}
                  icon={<Users size={18} />}
                  bg={"#1b30a7"}
                />
              )}
              lockScroll
            >
              {(close) => <AddAmbassador close={close} />}
            </Popup>
            <Popup
              closeOnDocumentClick={false}
              modal
              trigger={() => (
                <Button
                  value={"Envoyer une notificaton aux utilisateurs"}
                  icon={<Bell size={18} />}
                  bg={"#1b30a7"}
                />
              )}
              lockScroll
            >
              {(close) => <MessagePush close={close} />}
            </Popup>
          </div>
        </div>

        <div className="row-admin">
          <h2>Monnaies et reserves: {currencyData.length}</h2>
          <div className="grid">
            {currencyData.map((reserve, index) => {
              return <AdminReserveCard data={reserve} key={index} />;
            })}
          </div>
        </div>
        <div className="row-admin">
          <div className="grid">
            <h2>Point bonus</h2>
            <Popup
              closeOnDocumentClick={false}
              modal
              trigger={() => (
                <Button
                  value={"Modifier"}
                  icon={<Edit2 size={18} />}
                  bg={"#1b30a7"}
                />
              )}
              lockScroll
            >
              {(close) => (
                <form
                  action=""
                  className="login-form"
                  onSubmit={(e) => {
                    e.preventDefault();
                    dispatch(
                      addBonus(
                        point || bonusData.point,
                        prix || bonusData.prix,
                        minimum || bonusData.minimum,
                        pointParTransaction || bonusData.pointParTransaction,
                        close
                      )
                    );
                  }}
                >
                  <label htmlFor="">
                    <span>Nombre de point:</span>{" "}
                    <input
                      type="number"
                      min="1"
                      className="val-input"
                      defaultValue={bonusData.point}
                      onChange={(e) => setPoint(e.target.value)}
                      required
                    />
                  </label>
                  <label htmlFor="">
                    <span>Recompense (en $):</span>{" "}
                    <input
                      type="number"
                      min="1"
                      className="val-input"
                      defaultValue={bonusData.prix}
                      onChange={(e) => setPrix(e.target.value)}
                      required
                    />
                  </label>
                  <label htmlFor="">
                    <span>Retrait minimum de:</span>{" "}
                    <input
                      type="number"
                      min="1"
                      className="val-input"
                      defaultValue={bonusData.minimum}
                      onChange={(e) => setMinimum(e.target.value)}
                      required
                    />
                  </label>
                  <label htmlFor="">
                    <span>Point par transaction validée:</span>{" "}
                    <input
                      type="number"
                      min="1"
                      className="val-input"
                      defaultValue={bonusData.pointParTransaction}
                      onChange={(e) => setPointParTransaction(e.target.value)}
                      required
                    />
                  </label>
                  <div style={{ display: "flex" }}>
                    <Button
                      value={"Valider"}
                      type={"submit"}
                      icon={<CheckCircle size={18} />}
                      bg={"#1ba75a"}
                    />

                    <Button
                      value={"Annuler"}
                      icon={<XCircle size={18} />}
                      bg={"#d11b45"}
                      onClick={() => close()}
                    />
                  </div>
                </form>
              )}
            </Popup>
          </div>
          <div className="card2">
            <span>
              {bonusData.point ? bonusData.point : 0} points ={" "}
              {bonusData.prix ? bonusData.prix : 0}$ ,
            </span>{" "}
            <br />{" "}
            <span>
              {" "}
              Retrait min: {bonusData.minimum
                ? bonusData.minimum
                : 0} points{" "}
            </span>{" "}
            <br />
            <span>
              {" "}
              Point par transaction valide:{" "}
              {bonusData.pointParTransaction
                ? bonusData.pointParTransaction
                : 0}{" "}
              points{" "}
            </span>
          </div>
        </div>
        <div className="row-admin">
          <div className="switch-container">
            <SwitchSelector
              onChange={(value) => {
                setIsBar(value);
              }}
              options={switchOptions}
              initialSelectedIndex={switchOptions.findIndex(
                ({ value }) => value === "Ligne"
              )}
              backgroundColor={"#D0D0D0"}
              fontColor={"#000"}
            />
          </div>

          <div className="grid">
            <RNChart
              data={UserRegisterChartData}
              dataKey={"Personnes inscrites"}
              isBar={isBar}
            />
            <RNChart
              data={ExchangeChartData}
              dataKey={"Demandes de transaction"}
              isBar={isBar}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dash;
