import React, { useEffect } from "react";

const NotFound = () => {
  useEffect(() => {
    document.title = "Rien trouvé";
  }, []);

  return (
    <div className="page-content">
      <div className="notfound-container">
        <h1 className="error">404</h1>
        <p>Cette page n'existe pas</p>
      </div>
    </div>
  );
};

export default NotFound;
