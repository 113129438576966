import React from "react";
import { CheckCircle } from "react-feather";
import {
  LineChart,
  BarChart,
  Line,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend
} from "recharts";

const RNChart = ({ data, dataKey, isBar }) => {
  return isBar ? (
    <BarChart
      width={500}
      height={300}
      data={data}
      margin={{
        top: 5,
        right: 30,
        left: 20,
        bottom: 5
      }}
    >
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis dataKey="date" padding={{ left: 10, right: 10 }} tick />
      <YAxis />
      <Tooltip />
      <Legend />

      <Bar dataKey={dataKey} fill="var(--first-color)" background={"#fefe"} />
    </BarChart>
  ) : (
    <LineChart
      width={500}
      height={300}
      data={data}
      margin={{
        top: 5,
        right: 30,
        left: 20,
        bottom: 5
      }}
    >
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis dataKey="date" padding={{ left: 10, right: 10 }} />
      <YAxis />
      <Tooltip />
      <Legend />

      <Line
        type="monotone"
        dataKey={dataKey}
        stroke="var(--first-color)"
        strokeWidth={2}
      />
    </LineChart>
  );
};

export default RNChart;
