import React, { useEffect, useState } from "react";
import axios from "axios";
import ActivityIndicator from "../components/ActivityIndicator";
import validator from "validator";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router";
import { sendNotif } from "../Utils";
import {
  Eye,
  EyeOff,
  Lock,
  Mail,
  Phone,
  User,
  UserCheck,
  UserPlus
} from "react-feather";
import Button from "../components/Button";

const Register = () => {
  let navigate = useNavigate();

  const { codeParrain } = useParams();
  const [signPending, setSignPending] = useState(false);
  const [isReveal, setIsReveal] = useState(false);

  const [isReveal2, setIsReveal2] = useState(false);

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");
  const [verifyPass, setVerifyPass] = useState("");
  const [parrain, setParrain] = useState(codeParrain);

  useEffect(() => {
    document.title = "Vaancoin - Créer un compte";
    document.description =
      "Créer un compte afin de bénéficier de plein d'avantages.";
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  //Verify text field
  const verifyTrim = () => {
    if (!lastName.trim()) {
      sendNotif("Votre nom est requis.", "error");
      return false;
    }
    if (!firstName.trim()) {
      sendNotif("Votre prénom est requis.", "error");
      return false;
    }
    if (!email.trim()) {
      sendNotif("Votre email est requis.", "error");
      return false;
    } else {
      if (!validator.isEmail(email)) {
        sendNotif("Il vous faut un email valide.", "error");
        return false;
      }
    }
    if (!phone.trim()) {
      sendNotif("Votre numéro est requis.", "error");
      return false;
    }
    if (!password.trim()) {
      sendNotif("Votre mot de passe est requis.", "error");
      return false;
    } else if (password.length < 8) {
      sendNotif(
        "Votre mot de passe doit faire au moins 8 caractères.",
        "error"
      );
      return false;
    }
    if (password !== verifyPass) {
      sendNotif("Les 2 mots de passe ne correspondent pas.", "error");
      return false;
    } else return true;
  };

  ///REGISTER
  const handleSignup = (e) => {
    e.preventDefault();
    if (verifyTrim()) {
      setSignPending(true);

      const signInOptions = {
        method: "post",
        url: `${process.env.REACT_APP_SERVER}/api/user/signup`,
        withCredentials: true,
        data: {
          firstName,
          lastName,
          email,
          phone,
          password,
          parrain
        }
      };

      axios(signInOptions)
        .then((res) => {
          if (res.data.success) {
            setSignPending(false);
            sendNotif(res.data.message, "success");
            setFirstName("");
            setLastName("");
            setEmail("");
            setPhone("");
            setPassword("");
            setParrain("");
            setInterval(() => {
              window.location = "/account";
            }, 3000);
          } else {
            setSignPending(false);
            sendNotif(res.data.message, "error");
          }
        })
        .catch((err) => {
          setSignPending(false);
          sendNotif("Une erreur s'est produite.", "error");
          console.log(err);
        });
    }
  };
  /////

  return (
    <form action="" className="login-form" onSubmit={handleSignup}>
      <h1>Inscription</h1>

      <label htmlFor="">
        <span>Nom:</span>{" "}
        <span className="login-input">
          <i className="input-icon">
            <User size={18} />
          </i>
          <input
            type="text"
            onChange={(e) => setLastName(e.target.value)}
            value={lastName}
          />
        </span>
      </label>
      <label htmlFor="">
        <span>Prénom(s):</span>{" "}
        <span className="login-input">
          {" "}
          <i className="input-icon">
            <User size={18} />
          </i>
          <input
            type="text"
            onChange={(e) => setFirstName(e.target.value)}
            value={firstName}
          />
        </span>
      </label>
      <label htmlFor="">
        {" "}
        <span>Email:</span>{" "}
        <span className="login-input">
          {" "}
          <i className="input-icon">
            <Mail size={18} />
          </i>
          <input
            type="email"
            onChange={(e) => setEmail(e.target.value)}
            value={email}
          />
        </span>
      </label>
      <label htmlFor="">
        {" "}
        <span>Numéro de téléphone:</span>{" "}
        <span className="login-input">
          {" "}
          <i className="input-icon">
            <Phone size={18} />
          </i>
          <input
            type="tel"
            onChange={(e) => setPhone(e.target.value)}
            value={phone}
          />
        </span>
      </label>
      <label htmlFor="">
        <span>Mot de passe:</span>{" "}
        <span className="login-input">
          <i className="input-icon">
            <Lock size={18} />
          </i>
          <input
            type={isReveal ? "text" : "password"}
            name="password"
            onChange={(e) => setPassword(e.target.value)}
            value={password}
          />
          <i
            className="input-icon pointer"
            onClick={() => setIsReveal(!isReveal)}
          >
            {!isReveal ? <EyeOff size={18} /> : <Eye size={18} />}
          </i>
        </span>
      </label>
      <label htmlFor="">
        <span>Confirmer le mot de passe:</span>{" "}
        <span className="login-input">
          <i className="input-icon">
            <Lock size={18} />
          </i>
          <input
            type={isReveal2 ? "text" : "password"}
            name="password"
            onChange={(e) => setVerifyPass(e.target.value)}
            value={verifyPass}
          />
          <i
            className="input-icon pointer"
            onClick={() => setIsReveal2(!isReveal2)}
          >
            {!isReveal2 ? <EyeOff size={18} /> : <Eye size={18} />}
          </i>
        </span>
      </label>
      <label htmlFor="">
        <span>Code de parrainage:</span>{" "}
        <span className="login-input">
          {" "}
          <i className="input-icon">
            <UserCheck size={18} />
          </i>
          <input
            type="text"
            onChange={(e) => setParrain(e.target.value)}
            defaultValue={codeParrain && codeParrain}
          />
        </span>
      </label>
      {signPending ? (
        <ActivityIndicator />
      ) : (
        <Button
          value={"S'inscrire"}
          type={"submit"}
          icon={<UserPlus size={18} />}
          bg={"#0a47a2"}
        />
      )}
    </form>
  );
};

export default Register;
