import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { validateCashout } from "../actions/user.actions";
import { dateParser } from "../Utils";
import ActivityIndicator from "./ActivityIndicator";

const CashoutsCard = ({ cashout, handlecashoutsFetch }) => {
  const [pending, setPending] = useState(false);

  const dispatch = useDispatch();

  const handleValidate = () => {
    dispatch(validateCashout(cashout._id, setPending, handlecashoutsFetch));
  };

  return (
    <div className="card">
      <p>
        {" "}
        Utilisateur:{" "}
        <span className="variable">
          {cashout.userId.firstName} {cashout.userId.lastName}
        </span>
      </p>
      <p>
        id: <span className="variable">{cashout._id}</span>
      </p>
      <p>
        Point ennvoyé: <span className="variable">{cashout.point}</span>
      </p>

      <p>
        {" "}
        Doit recevoir: <span className="variable">
          {" "}
          {cashout.typeCurrency}
        </span>{" "}
        au: <span className="variable"> {cashout.adresse}</span>
      </p>

      <div className="bottom">
        <span>Date: {dateParser(cashout.createdAt)}</span>
        <span>
          status:{" "}
          {cashout.isValidate ? (
            <span className="success">validé</span>
          ) : (
            <span className="error">En attente</span>
          )}
        </span>
        {cashout.isValidate ? null : pending ? (
          <ActivityIndicator />
        ) : (
          <input
            type="button"
            className="button"
            value={"Valider le cashout"}
            onClick={handleValidate}
          />
        )}
      </div>
    </div>
  );
};

export default CashoutsCard;
