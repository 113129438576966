import { GET_BONUS, ADD_BONUS } from "../actions/bonus.actions";

const initialState = {};

export default function bonusReducer(state = initialState, action) {
  switch (action.type) {
    case GET_BONUS:
      return action.payload;
    case ADD_BONUS:
      return {
        ...state,
        point: action.payload.point,
        prix: action.payload.prix,
        minimum: action.payload.minimum,
        pointParTransaction: action.payload.pointParTransaction
      };

    default:
      return state;
  }
}
