import axios from "axios";
import { sendNotif } from "../Utils";

export const GET_BONUS = "GET_BONUS";
export const ADD_BONUS = "ADD_BONUS";

export const getBonus = () => {
  return (dispatch) => {
    return axios
      .get(`${process.env.REACT_APP_SERVER}/api/bonus`)
      .then((res) => {
        dispatch({ type: GET_BONUS, payload: res.data });
      })
      .catch((err) => console.log(err));
  };
};

export const addBonus = (point, prix, minimum, pointParTransaction, close) => {
  return (dispatch) => {
    return axios({
      method: "post",
      url: `${process.env.REACT_APP_SERVER}/api/bonus/`,
      withCredentials: true,
      data: { point, prix, minimum, pointParTransaction }
    })
      .then((res) => {
        if (res.data.success) {
          sendNotif(res.data.message, "success");
          close();

          dispatch({
            type: ADD_BONUS,
            payload: {
              point,
              prix,
              minimum,
              pointParTransaction
            }
          });
        } else {
          sendNotif(res.data.message, "error");
        }
      })
      .catch((err) => {
        console.log(err);
        sendNotif("Une erreur s'est produite", "error");
      });
  };
};
