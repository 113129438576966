import React, { useState } from "react";
import ActivityIndicator from "./ActivityIndicator";
import { useDispatch, useSelector } from "react-redux";
import { updateComment } from "../actions/comments.actions";
import { sendNotification } from "../Utils";
import Button from "./Button";
import { CheckCircle } from "react-feather";

const UpdateComment = ({ close, data }) => {
  const userData = useSelector((state) => state.userReducer);
  const usersData = useSelector((state) => state.usersReducer);

  const [comment, setComment] = useState("");
  const [pending, setPending] = useState(false);

  const dispatch = useDispatch();

  const handleUpdateComment = async (e) => {
    e.preventDefault();
    setPending(true);
    dispatch(
      updateComment(data._id, comment || data.comment, setPending, close)
    );
    sendNotification(
      `${userData.firstName} ${
        userData.lastName
      } a mis a jour son commentaire pour ${comment || data.comment}`,
      usersData.filter((user) => user.isAdmin).map((data) => data._id)
    );
  };

  return (
    <form onSubmit={handleUpdateComment} className="login-form">
      <span className="close" onClick={close}>
        &#x2715;
      </span>
      <h2>Mise à jour</h2>
      <textarea
        className="comment-input"
        placeholder="Votre commentaire ici..."
        resize="none"
        onChange={(e) => {
          setComment(e.target.value);
        }}
        defaultValue={data.comment}
      ></textarea>
      {pending ? (
        <ActivityIndicator />
      ) : (
        <Button
          value={"Mise à jour"}
          icon={<CheckCircle size={18} />}
          bg={"#1b30a7"}
          type={"submit"}
        />
      )}
    </form>
  );
};

export default UpdateComment;
