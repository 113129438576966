import axios from "axios";
import { Resquest, sendNotif } from "../Utils";
import { SEND_ERRORS } from "./user.actions";

export const GET_PUB = "GET_PUB";
export const ADD_PUB = "ADD_PUB";

export const getPub = () => {
  return (dispatch) => {
    return Resquest.get(`${process.env.REACT_APP_SERVER}/api/user/pub`)
      .then((res) => {
        dispatch({ type: GET_PUB, payload: res.data });
      })
      .catch((err) => console.log(err));
  };
};

export const _addPub = (data, setPending, close) => {
  return (dispatch) => {
    setPending(true);
    return Resquest.post(`${process.env.REACT_APP_SERVER}/api/user/pub`, data, {
      withCredentials: true
    })
      .then((res) => {
        if (res.data.errors) {
          dispatch({ type: SEND_ERRORS, playload: res.data.errors });
          setPending(false);
        } else if (res.data.success) {
          setPending(false);
          sendNotif(res.data.message, "success");
          close();
          dispatch(getPub());
        } else {
          sendNotif(res.data.message, "error");
          setPending(false);
        }
      })
      .catch((err) => {
        setPending(false);
        console.log(err);
      });
  };
};

export const _deletePub = (setDeletePending) => {
  return (dispatch) => {
    setDeletePending(true);
    return Resquest.delete(`${process.env.REACT_APP_SERVER}/api/user/pub`)
      .then((res) => {
        if (res.data.success) {
          setDeletePending(false);
          sendNotif(res.data.message, "success");
          dispatch(getPub());
        } else {
          sendNotif(res.data.message, "error");
          setDeletePending(false);
        }
      })
      .catch((err) => {
        setDeletePending(false);
        console.log(err);
      });
  };
};
