import React, { useEffect, useState } from "react";
import Loading from "../components/Loading";

const Error = ({ message }) => {
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    document.title = "Oups désolé";
    window.scrollTo({ top: 0, behavior: "smooth" });
    setLoading(false);
  }, []);

  if (loading) {
    return <Loading />;
  }
  return (
    <div className="page-content">
      <div className="about-container">
        <h2 className="error">{message}</h2>
        <input
          type="button"
          value="Accueil"
          className="button"
          onClick={() => (window.location = "/")}
        />
      </div>
    </div>
  );
};

export default Error;
