import axios from "axios";
import { Resquest, sendNotif, sendNotification } from "../Utils";
import { getUsers } from "./users.actions";

export const GET_USER = "GET_USER";
export const UPDATE_USER = "UPDATE_USER";
export const SEND_ERRORS = "SEND_ERRORS";

export const getUser = (uid) => {
  return (dispatch) => {
    return Resquest.get(
      `${process.env.REACT_APP_SERVER}/api/user/profile/${uid}`
    )
      .then((res) => {
        dispatch({ type: GET_USER, payload: res.data.user });
      })
      .catch((err) => console.log(err));
  };
};

export const _updateProfile = (
  firstName,
  lastName,
  email,
  phone,
  setPending,
  close
) => {
  console.log(setPending);
  setPending(true);
  return (dispatch) => {
    return Resquest.put(`${process.env.REACT_APP_SERVER}/api/user/profile`, {
      firstName,
      lastName,
      email,
      phone
    })
      .then((res) => {
        if (res.data.success) {
          setPending(false);
          close();
          sendNotif(res.data.message, "success");
          dispatch({
            type: UPDATE_USER,
            payload: { firstName, lastName, email, phone }
          });
        } else {
          setPending(false);
          sendNotif(res.data.message, "error");
        }
      })
      .catch((err) => {
        sendNotif("Une erreur s'est produite.", "error");
        setPending(false);
        console.log(err);
      });
  };
};

export const sendVerification = (data, setSendPending, close, usersData) => {
  setSendPending(true);

  return (dispatch) => {
    return axios
      .post(`${process.env.REACT_APP_SERVER}/api/user/userverify`, data)
      .then((res) => {
        if (res.data.errors) {
          dispatch({ type: SEND_ERRORS, playload: res.data.errors });
          setSendPending(false);
        } else if (res.data.success) {
          sendNotif(res.data.message, "success");
          sendNotification(
            "Nouvelle demande de vérification de compte",
            usersData
          );
          close();
          sendNotification(`Nouvelle demande de vériification de compte".`);
          setSendPending(false);
          setTimeout(() => {
            window.location = "/profil";
          }, 4000);
        } else {
          sendNotif(res.data.message, "error");
          setSendPending(false);
        }
      })
      .catch((err) => {
        setSendPending(false);
        console.log(err);
      });
  };
};

export const verifyUser = (userId, setVerifyPending) => {
  setVerifyPending(true);
  return (dispatch) => {
    return Resquest.post(
      `${process.env.REACT_APP_SERVER}/api/user/userverify/${userId}`
    )
      .then((res) => {
        if (res.data.success) {
          sendNotif(res.data.message, "success");
          setVerifyPending(false);
          setTimeout(() => {
            window.location = "/dashboard/verifications";
          }, 4000);
        } else {
          sendNotif(res.data.message, "error");
          setVerifyPending(false);
        }
      })
      .catch((err) => {
        setVerifyPending(false);
        console.log(err);
      });
  };
};
export const unVerifyUser = (id, setDetelePending) => {
  setDetelePending(true);
  return (dispatch) => {
    return Resquest.delete(
      `${process.env.REACT_APP_SERVER}/api/user/userverify/${id}`
    )
      .then((res) => {
        if (res.data.success) {
          sendNotif(res.data.message, "success");
          setDetelePending(false);
          setTimeout(() => {
            window.location = "/dashboard/verifications";
          }, 4000);
        } else {
          sendNotif(res.data.message, "error");
          setDetelePending(false);
        }
      })
      .catch((err) => {
        setDetelePending(false);
        console.log(err);
      });
  };
};
//Validate transaction
export const validateTransaction = (
  transaction,
  setPending,
  handleTransactionsFetch,
  userId
) => {
  setPending(true);
  return async (dispatch) => {
    try {
      const res = await Resquest.post(
        `${process.env.REACT_APP_SERVER}/api/exchange/${transaction._id}`
      );
      if (res.data.success) {
        sendNotif(res.data.message, "success");
        sendNotification(`Votre demande de transaction vient d'être validé.`, [
          transaction.userId?._id
        ]);
        setPending(false);
        handleTransactionsFetch();
      } else {
        setPending(false);
        sendNotif(res.data.message, "error");
      }
    } catch (err) {
      setPending(false);
      console.log(err);
    }
  };
};

//Delete transaction
export const deleteTransaction = (
  transaction,
  setPending,
  handleTransactionsFetch
) => {
  setPending(true);
  return async (dispatch) => {
    try {
      const res = await Resquest.delete(
        `${process.env.REACT_APP_SERVER}/api/exchange/${transaction._id}`
      );
      if (res.data.success) {
        sendNotif(res.data.message, "success");
        sendNotification(`Votre demande de transaction vient d'être annulée.`, [
          transaction.userId?._id
        ]);
        setPending(false);
        handleTransactionsFetch();
      } else {
        setPending(false);
        sendNotif(res.data.message, "error");
      }
    } catch (err) {
      sendNotif("Une erreur s'est produite", "error");
      setPending(false);
      console.log(err);
    }
  };
};

//Validate cashout
export const validateCashout = (
  id,

  setPending,
  handlecashoutsFetch
) => {
  setPending(true);
  return (dispatch) => {
    return axios
      .post(`${process.env.REACT_APP_SERVER}/api/bonus/cashout/validate/${id}`)
      .then((res) => {
        if (res.data.success) {
          sendNotif(res.data.message, "success");
          setPending(false);
          setTimeout(() => {
            handlecashoutsFetch();
          }, 3000);
        } else {
          setPending(false);
          sendNotif(res.data.message, "error");
        }
      })
      .catch((err) => {
        setPending(false);
        console.log(err);
      });
  };
};

export const updateAdminStatus = (id, isOnline, usersData) => {
  return async (dispatch) => {
    const res = await axios({
      method: "put",
      url: `${process.env.REACT_APP_SERVER}/api/user/profile/status/` + id,
      data: { isOnline }
    });
    if (res.data.success) {
      sendNotif(res.data.message, "success");
      sendNotification(res.data.notification, usersData);
      dispatch(getUsers());
    } else {
      sendNotif(res.data.message, "error");
    }
  };
};
