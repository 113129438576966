import axios from "axios";
import { sendNotif, Resquest } from "../Utils";

export const GET_COMMENT = "GET_COMMENT";
export const UPDATE_COMMENT = "UPDATE_COMMENT";
export const DELETE_COMMENT = "DELETE_COMMENT";

export const getComments = () => {
  return (dispatch) => {
    return axios
      .get(`${process.env.REACT_APP_SERVER}/api/comment`)
      .then((res) => {
        dispatch({ type: GET_COMMENT, payload: res.data });
      })
      .catch((err) => console.log(err));
  };
};

export const updateComment = (
  commentId,
  comment,
  setPending,

  close
) => {
  return (dispatch) => {
    setPending(true);
    return Resquest.put(
      `${process.env.REACT_APP_SERVER}/api/comment/` + commentId,
      { comment }
    )
      .then((res) => {
        if (res.data.success) {
          setPending(false);
          sendNotif(res.data.message, "success");
          close();
          dispatch({
            type: UPDATE_COMMENT,
            payload: {
              commentId,
              comment
            }
          });
        } else {
          sendNotif(res.data.error, "error");
        }
      })
      .catch((err) => {
        setPending(false);
        console.log(err);
      });
  };
};

export const deleteComment = (commentId) => {
  return (dispatch) => {
    return Resquest.delete(
      `${process.env.REACT_APP_SERVER}/api/comment/${commentId}`
    )
      .then((res) => {
        if (res.data.success) {
          sendNotif(res.data.message, "success");
          dispatch({ type: DELETE_COMMENT, payload: { commentId } });
        } else {
          sendNotif(res.data.error, "error");
        }
      })
      .catch((err) => console.log(err));
  };
};
