import {
  GET_CURRENCY,
  UPDATE_CURRENCY,
  DELETE_CURRENCY
} from "../actions/currency.actions";

const initialState = [];

export default function currencyReducer(state = initialState, action) {
  switch (action.type) {
    case GET_CURRENCY:
      return action.payload;
    case UPDATE_CURRENCY:
      return state.map((currency) => {
        if (currency._id === action.payload.currencyId) {
          return {
            ...currency,
            name: action.payload.name,
            image: action.payload.image,
            devise: action.payload.devise,
            sellRate: action.payload.sellRate,
            buyRate: action.payload.buyRate,
            receiver: action.payload.receiver,
            reserve: action.payload.reserve,
            minimum: action.payload.minimum,
            maximal: action.payload.maximal,
            toFixed: action.payload.toFixed,
            isCrypto: action.payload.isCrypto
          };
        } else return currency;
      });
    case DELETE_CURRENCY:
      return state.filter(
        (currency) => currency._id !== action.payload.currencyId
      );
    default:
      return state;
  }
}
