import {
  GET_COMMENT,
  UPDATE_COMMENT,
  DELETE_COMMENT
} from "../actions/comments.actions";

const initialState = [];

export default function commentReducer(state = initialState, action) {
  switch (action.type) {
    case GET_COMMENT:
      return action.payload;
    case UPDATE_COMMENT:
      return state.map((comment) => {
        if (comment._id === action.payload.commentId) {
          return {
            ...comment,
            comment: action.payload.comment
          };
        } else return comment;
      });
    case DELETE_COMMENT:
      return state.filter(
        (comment) => comment._id !== action.payload.commentId
      );
    default:
      return state;
  }
}
