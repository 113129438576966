import React, { useContext, useState, useEffect } from "react";
import { Routes, Route, BrowserRouter, NavLink } from "react-router-dom";
import {
  Home,
  About,
  Faq,
  Profile,
  Error,
  NotFound,
  Validate,
  History,
  Dash,
  Verify,
  VerifyUser,
  Users,
  Transactions,
  Conditions,
  Commentaires,
  Parrainage,
  Cashouts,
  ResetPassword,
} from "../screens";
import Nav from "../Navigations/Nav";
import Footer from "../components/Footer";
import { UidContext } from "../components/AppContext";
import { useSelector } from "react-redux";
import Whatsapp from "../components/Whatsapp";
import Account from "../components/Account";
import NewPassword from "../screens/NewPassword";

import Ambassadeurs from "../screens/Ambassadeurs";
import Images from "../constantes/images";
import Status from "../components/Status";
import { isWebView } from "../Utils";

const Router = () => {
  const userData = useSelector((state) => state.userReducer);
  const uid = useContext(UidContext);
  const [windowDimension, setWindowDimension] = useState(null);
  const isMobile = windowDimension <= 600;
  const [verdict, setVerdict] = useState("");
  useEffect(() => {
    setWindowDimension(window.innerWidth);
  }, []);
  useEffect(() => {
    function handleResize() {
      setWindowDimension(window.innerWidth);
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    const res = isWebView();
    setVerdict(res);
  }, []);

  return (
    <BrowserRouter>
      {isMobile && (
        <div className="logo-top">
          <NavLink className="logo-link" to={"/"}>
            <img src={Images.logo} alt="logo" />
          </NavLink>
        </div>
      )}
      <Nav />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/a-propos" element={<About />} />
        <Route path="/conditions" element={<Conditions />} />
        <Route path="/faq" element={<Faq />} />

        <Route path="/password-reset" element={<ResetPassword />} />
        <Route path="/new-password" element={<NewPassword />} />
        <Route
          path="/account"
          element={uid ? <Home /> : <Account toScreen={"Connexion"} />}
        />

        <Route
          path="/account/referral/:codeParrain"
          element={
            uid ? (
              <Error message="Non vous êtes déjà connecté. " />
            ) : (
              <Account toScreen={"Inscription"} />
            )
          }
        />
        <Route path="/commentaires" element={<Commentaires />} />

        <Route path="/transaction" element={<Validate />} />
        <Route
          path="/profil"
          element={
            uid ? (
              !userData.isAdmin ? (
                <Profile />
              ) : (
                <Error message="Oops, désolé vous ailleurs là." />
              )
            ) : (
              <Account toScreen={"Connexion"} />
            )
          }
        />
        <Route
          path="/dashboard"
          element={
            uid ? (
              userData.isAdmin ? (
                <Dash />
              ) : (
                <Error message="Oops, désolé vous n'avez pas ce droit." />
              )
            ) : (
              <Account toScreen={"Connexion"} />
            )
          }
        />
        <Route
          path="/dashboard/verifications"
          element={
            uid ? (
              userData.isAdmin ? (
                <Verify />
              ) : (
                <Error message="Oops, désolé vous n'avez pas ce droit." />
              )
            ) : (
              <Account toScreen={"Connexion"} />
            )
          }
        />
        <Route
          path="/dashboard/verifications/:id"
          element={
            uid ? (
              userData.isAdmin ? (
                <VerifyUser />
              ) : (
                <Error message="Oops, désolé vous n'avez pas ce droit." />
              )
            ) : (
              <Account toScreen={"Connexion"} />
            )
          }
        />
        <Route
          path="/dashboard/transactions"
          element={
            uid ? (
              userData.isAdmin ? (
                <Transactions />
              ) : (
                <Error message="Oops, désolé vous n'avez pas ce droit." />
              )
            ) : (
              <Account toScreen={"Connexion"} />
            )
          }
        />
        <Route
          path="/dashboard/cashout"
          element={
            uid ? (
              userData.isAdmin ? (
                <Cashouts />
              ) : (
                <Error message="Oops, désolé vous n'avez pas ce droit." />
              )
            ) : (
              <Account toScreen={"Connexion"} />
            )
          }
        />
        <Route
          path="/dashboard/users"
          element={
            uid ? (
              userData.isAdmin ? (
                <Users />
              ) : (
                <Error message="Oops, désolé vous n'avez pas ce droit." />
              )
            ) : (
              <Account toScreen={"Connexion"} />
            )
          }
        />
        <Route
          path="/dashboard/ambassadeurs"
          element={
            uid ? (
              userData.isAdmin ? (
                <Ambassadeurs />
              ) : (
                <Error message="Oops, désolé vous n'avez pas ce droit." />
              )
            ) : (
              <Account toScreen={"Connexion"} />
            )
          }
        />
        <Route
          path="/history"
          element={uid ? <History /> : <Account toScreen={"Connexion"} />}
        />

        <Route
          path="/profil/parrainage"
          element={uid ? <Parrainage /> : <Account toScreen={"Connexion"} />}
        />

        <Route path="*" element={<NotFound />} />
      </Routes>

      <Status />

      <Whatsapp />

      {verdict !== "ios webview" && <Footer />}
    </BrowserRouter>
  );
};

export default Router;
