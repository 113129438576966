/* eslint-disable jsx-a11y/img-redundant-alt */
import React from "react";
import { NavLink } from "react-router-dom";

const CheckList = ({ data }) => {
  return (
    <NavLink
      to={`/dashboard/verifications/${data._id}`}
      state={{ userId: data._id }}
      className="list-container"
    >
      <p className="to-check">
        {data.verifierId.firstName} {data.verifierId.lastName}
      </p>
    </NavLink>
  );
};

export default CheckList;
