/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import Loading from "../components/Loading";
import axios from "axios";
import { useSelector } from "react-redux";
import { dateParser } from "../Utils";
import Pagination from "../components/Pagination";

const History = () => {
  const userData = useSelector((state) => state.userReducer);
  const [loading, setLoading] = useState(true);
  const [historyData, setHistoryData] = useState([]);
  const [items, setItems] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);

  const [error, setError] = useState(null);

  const handleHistoryFetch = async () => {
    await axios
      .get(`${process.env.REACT_APP_SERVER}/api/exchange/${userData._id}`)
      .then((res) => {
        if (res.data.success) {
          setHistoryData(res.data.history);
          setError(null);
        } else {
          setError(res.data.message);
        }
      })
      .catch((err) => {
        setHistoryData([]);
        console.log(err);
      });
    return true;
  };
  useEffect(() => {
    document.title = "Historique des transactions";
    window.scrollTo({ top: 0, behavior: "smooth" });
    if (handleHistoryFetch()) {
      setLoading(false);
    }
  }, [userData]);
  if (loading) {
    return <Loading />;
  }
  return (
    <div className="page-content">
      <div className="historique-container">
        {error ? (
          <div className="card error">{error}</div>
        ) : (
          <>
            {items.length > 0 ? (
              items.map((history) => {
                return (
                  <div className="card2" key={history._id}>
                    <p>
                      Montant envoyé:{" "}
                      <span className="variable">
                        {history.resume.isGiven
                          ? history.resume.toConvert
                          : history.resume.result}{" "}
                        {history.resume.fcurrency.devise}{" "}
                        {history.resume.fcurrency.name}
                      </span>
                    </p>
                    <p>
                      Doit recevoir:{" "}
                      <span className="variable">
                        {" "}
                        {history.resume.isGiven
                          ? history.resume.result
                          : history.resume.toConvert}{" "}
                        {history.resume.tcurrency.devise}{" "}
                        {history.resume.tcurrency.name}
                      </span>
                    </p>
                    <p>
                      {" "}
                      au: <span className="variable"> {history.adresse}</span>
                    </p>
                    <p>
                      ref: <span className="variable"> {history.ref}</span>
                    </p>

                    <span className="small">
                      Date: {dateParser(history.createdAt)}
                    </span>
                    <span>
                      status:{" "}
                      {history.isValidate ? (
                        <span className="success">validé</span>
                      ) : (
                        <span className="error">En attente</span>
                      )}
                    </span>
                  </div>
                );
              })
            ) : (
              <div className="card error">Pas d'historique</div>
            )}
          </>
        )}
      </div>
      <Pagination
        data={historyData}
        totalCount={historyData.length}
        setItems={setItems}
        className="pagination-bar"
        currentPage={currentPage}
        pageSize={4}
        onPageChange={(page) => setCurrentPage(page)}
      />
    </div>
  );
};

export default History;
