import React, { useRef } from "react";

const Button = ({ onClick, icon, value, bg, className, type }) => {
  const ref = useRef();
  return (
    <button
      ref={ref}
      className={`button2 ${className}`}
      style={{ backgroundColor: bg }}
      onClick={onClick}
      type={type}
    >
      <span className="btn-icon">{icon}</span>
      <span className="btn-text">{value}</span>
    </button>
  );
};

export default Button;
