import React from "react";
import {
  Trash2,
  Edit,
  Circle,
  MoreHorizontal,
  MoreVertical
} from "react-feather";
import { useDispatch } from "react-redux";
import Popup from "reactjs-popup";
import { deleteCurrency } from "../actions/currency.actions";
import Button from "./Button";
import Popover from "./Popover";
import UpdateMonnaie from "./UpdateMonnaie";

const AdminReserveCard = ({ data }) => {
  const dispatch = useDispatch();
  const handleDelete = (e) => {
    e.preventDefault();
    const accord = window.confirm("Voulez-vous vraiment le supprimer ?");
    if (accord) {
      dispatch(deleteCurrency(data._id));
    }
  };

  return (
    <div className="card">
      <div className="top">
        <img src={data.image} alt="" /> <p>{data.name}</p>{" "}
        <Popover
          triger={
            <span className="options">
              <MoreVertical size={18} />
            </span>
          }
          children={
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                backgroundColor: "white",
                padding: "10px"
              }}
            >
              <Popup
                modal={true}
                nested
                trigger={
                  <Button
                    value={"Modifier"}
                    icon={<Edit size={18} />}
                    bg={"#1ba747be"}
                  />
                }
                position="center"
              >
                {(close) => <UpdateMonnaie data={data} close={close} />}
              </Popup>

              <Button
                value={"Supprimer"}
                icon={<Trash2 size={18} />}
                bg={"#a71b1bb0"}
                onClick={handleDelete}
              />
            </div>
          }
        />
      </div>
      {data.isCrypto ? (
        <div>
          <span className="price">Taux vente: {data.sellRate}</span>

          <span className="price">Taux achat: {data.buyRate}</span>
        </div>
      ) : null}
      <div className="bottom">
        <p>
          {data.reserve} {data.devise}
        </p>
      </div>
    </div>
  );
};

export default AdminReserveCard;
