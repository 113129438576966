import React, { useState, useContext } from "react";
import ActivityIndicator from "../components/ActivityIndicator";
import { isEmpty, sendNotif } from "../Utils";
import axios from "axios";
import { useSearchParams } from "react-router-dom";
import { UidContext } from "../components/AppContext";
import { CheckCircle } from "react-feather";
import Button from "../components/Button";

const NewPassword = () => {
  const uid = useContext(UidContext);

  let [searchParams, setSearchParams] = useSearchParams();
  const [sendPending, setSendPending] = useState(false);
  let token = searchParams.get("token");
  let id = searchParams.get("id");

  const [password, setPassword] = useState(null);

  const [confirmPassword, setConfirmPassword] = useState(null);

  const handleReset = (e) => {
    e.preventDefault();
    setSendPending(true);

    if (isEmpty(password)) {
      sendNotif("Entrez votre nouveau mot de passe!", "error");
      setSendPending(false);
    } else if (password.length < 8) {
      sendNotif(
        "Votre mot de passe doit faire au moins 8 caractères.",
        "error"
      );
      setSendPending(false);
    } else if (isEmpty(confirmPassword)) {
      sendNotif("Entrez la confirmation votre nouveau mot de passe!", "error");
      setSendPending(false);
    } else if (confirmPassword !== password) {
      sendNotif("Les deux mots de passe ne correspondent pas!", "error");
      setSendPending(false);
    } else {
      const resetOptions = {
        method: "put",
        url: `${process.env.REACT_APP_SERVER}/api/user/resetPassword/${id}`,
        withCredentials: true,
        data: {
          token,
          password
        }
      };
      axios(resetOptions)
        .then((res) => {
          if (!res.data.success) {
            setSendPending(false);
            sendNotif(res.data.message, "error");
          } else {
            setSendPending(false);
            sendNotif(res.data.message, "success");
            setTimeout(() => {
              window.location = uid ? "/" : "/login";
            }, 4000);
          }
        })
        .catch((err) => {
          setSendPending(false);
          sendNotif("Une erreur s'est produite, éssayez plus tard", "error");
          console.log(err);
        });
    }
  };

  return (
    <div className="page-content">
      <div className="form-container">
        <form className="login-form" onSubmit={handleReset}>
          <h1>Entrez votre mot de passe</h1>
          <label htmlFor="">
            <span>Nouveau mot de passe</span>
            <input
              className="login-input"
              placeholder="Nouveau mot de passe"
              type="password"
              onChange={(e) => setPassword(e.target.value)}
            />
          </label>
          <label htmlFor="">
            <span>Retapez le nouveau mot de passe</span>
            <input
              className="login-input"
              placeholder="Nouveau mot de passe"
              type="password"
              onChange={(e) => setConfirmPassword(e.target.value)}
            />
          </label>
          {sendPending ? (
            <ActivityIndicator />
          ) : (
            <Button
              value={"Confirmer le changement"}
              type={"submit"}
              icon={<CheckCircle size={18} />}
              bg={"#0a47a2"}
            />
          )}
        </form>
      </div>
    </div>
  );
};

export default NewPassword;
