import React from "react";
import * as PopOver from "@radix-ui/react-popover";

const Popover = ({ triger, children }) => {
  return (
    <PopOver.Root>
      <PopOver.Trigger asChild>{triger}</PopOver.Trigger>
      <PopOver.Anchor />
      <PopOver.Portal>
        <PopOver.Content>
          {children}
          <PopOver.Close aria-label="close" />
          <PopOver.Arrow />
        </PopOver.Content>
      </PopOver.Portal>
    </PopOver.Root>
  );
};

export default Popover;
