/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState, useRef } from "react";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import ExchengeForm from "../components/ExchengeForm";
import { dateParser, sendNotif, handleLogout } from "../Utils";
import Loading from "../components/Loading";
import ActivityIndicator from "../components/ActivityIndicator";
import axios from "axios";
import { Archive, Edit, Shield, User, LogOut } from "react-feather";
import Popup from "reactjs-popup";
import UpdateProfile from "./UpdateProfile";
import VerifyProfile from "./VerifyProfile";

const Profile = () => {
  const userData = useSelector((state) => state.userReducer);

  const [loading, setLoading] = useState(true);
  const [pending, setPending] = useState(false);

  const [copySuccess, setCopySuccess] = useState(null);
  const textAreaRef = useRef(null);

  function updateClipboard(newClip) {
    navigator.clipboard.writeText(newClip).then(
      () => {
        setCopySuccess("Copié!");
        setInterval(() => {
          setCopySuccess(null);
        }, 3000);
      },
      () => {
        setCopySuccess("Copie échouée!");
        setInterval(() => {
          setCopySuccess(null);
        }, 3000);
      }
    );
  }

  function copyToClipboard() {
    navigator.permissions.query({ name: "clipboard-write" }).then((result) => {
      if (result.state === "granted" || result.state === "prompt") {
        updateClipboard(textAreaRef.current?.innerText);
      }
    });
  }

  useEffect(() => {
    document.title = "Mon profil";
    window.scrollTo({ top: 0, behavior: "smooth" });
    setLoading(false);
  }, []);

  const handleSendEmailVerification = () => {
    setPending(true);

    const sendOptions = {
      method: "post",
      url: `${process.env.REACT_APP_SERVER}/api/user/sendVerifyEmail/${userData._id}`,
      withCredentials: true
    };
    axios(sendOptions)
      .then((res) => {
        if (!res.data.success) {
          setPending(false);
          sendNotif(res.data.message, "error");
        } else {
          setPending(false);
          sendNotif(res.data.message, "success");
        }
      })
      .catch((err) => {
        setPending(false);
        sendNotif("Une erreur s'est produite, éssayez plus tard", "error");
        console.log(err);
      });
  };

  if (loading) {
    return <Loading />;
  }
  return (
    <div className="page-content">
      <div className="profile-container">
        <div className="bgWhite grid">
          <NavLink to="/history" className="nav-link">
            <span className="nav-link">
              <span className="nav-icon">
                <Archive size={18} />
              </span>
              <span className="nav-text">Historique</span>
            </span>
          </NavLink>
          {!userData.isVerifiedCredentials && (
            <Popup
              closeOnDocumentClick={false}
              modal
              trigger={() => (
                <span className="nav-link">
                  <span className="nav-icon">
                    <Shield size={18} />
                  </span>
                  <span className="nav-text">Vérifié mon compte</span>
                </span>
              )}
              lockScroll
            >
              {(close) => <VerifyProfile close={close} />}
            </Popup>
          )}

          <Popup
            closeOnDocumentClick={false}
            modal
            trigger={() => (
              <span className="nav-link">
                <span className="nav-icon">
                  <Edit size={18} />
                </span>
                <span className="nav-text">Modifier mon profil</span>
              </span>
            )}
            lockScroll
          >
            {(close) => <UpdateProfile close={close} />}
          </Popup>
          {userData.referrers?.length > 0 && (
            <NavLink to="/profil/parrainage" className="nav-link">
              {" "}
              <span className="nav-link">
                <span className="nav-icon">
                  <User size={18} />
                </span>
                <span className="nav-text">
                  {" "}
                  Personnes parrainées{" "}
                  {userData.referrers ? userData.referrers?.length : "0"}{" "}
                </span>
              </span>
            </NavLink>
          )}
          <div className="nav-link">
            <span onClick={handleLogout} className="nav-link">
              <span className="nav-icon">
                <LogOut size={18} />
              </span>
              <span className="nav-text">Déconnexion</span>
            </span>
          </div>
        </div>
        <span className="code-parrain" onClick={copyToClipboard}>
          {copySuccess ? (
            <span className="success">{copySuccess}</span>
          ) : (
            "Mon lien de parrainage:"
          )}
          <span className="success" ref={textAreaRef}>
            {process.env.REACT_APP_CLIENT}/account/referral/
            {userData.codeParrain}
          </span>
        </span>
        <div className="main-info">
          <h2>
            {userData.firstName} {userData.lastName}{" "}
            {userData.isVerifiedCredentials ? (
              <span className="success small">compte vérifié</span>
            ) : (
              <span className="error small">compte non vérifié</span>
            )}{" "}
            <span className="small">
              Membre depuis: ({dateParser(userData.createdAt)})
            </span>
          </h2>{" "}
          <span>
            mail:<span className="variable"> {userData.email}</span>{" "}
            {userData.verified ? (
              <span className="success small">email vérifié</span>
            ) : (
              <span>
                <span className="error small">email non vérifié </span>
                {pending ? (
                  <ActivityIndicator />
                ) : (
                  <span
                    className="button2"
                    onClick={handleSendEmailVerification}
                  >
                    Vérifié mon email
                  </span>
                )}
              </span>
            )}
          </span>{" "}
          <br />
          phone: <span className="variable"> {userData.phone}</span>
        </div>
        <div className="form-container">
          <ExchengeForm />
        </div>
      </div>
    </div>
  );
};

export default Profile;
