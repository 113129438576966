import React from "react";
import Images from "../constantes/images";
import { CheckCircle, Loader, Repeat } from "react-feather";

const HomeHistorique = ({ data, green, red }) => {
  return (
    <div className="historique-row">
      {data.userId ? (
        <p className="historique-symbol">
          {data.userId.firstName} {data.userId.lastName}
        </p>
      ) : (
        <p className="historique-symbol">User {Date.now().toString(36)}</p>
      )}
      <div className="historique-data">
        <img src={data.resume.tcurrency.image} alt="" />{" "}
        {data.resume.isGiven ? data.resume.result : data.resume.toConvert}{" "}
        {data.resume.tcurrency.devise}
      </div>

      <div className="historique-data">
        <Repeat size={20} />
      </div>
      <div className="historique-data">
        {" "}
        <img src={data.resume.fcurrency.image} alt="" />{" "}
        {data.resume.isGiven ? data.resume.toConvert : data.resume.result}{" "}
        {data.resume.fcurrency.devise}
      </div>
      <div className="historique-data">
        {green && <CheckCircle size={20} color={`var(--light-green)`} />}
        {red && <Loader size={20} color={`var(--light-red)`} />}
      </div>
    </div>
  );
};

export default HomeHistorique;
