/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { NavLink } from "react-router-dom";
import Images from "../constantes/images";

const Footer = () => {
  return (
    <footer className="footer">
      <div className="grid">
        <div className="block block-1">
          <NavLink to="/" className="nav-logo">
            <img src={Images.logo} alt="logo" />
          </NavLink>

          <NavLink className="links" to="/a-propos">
            Qui sommes nous
          </NavLink>
          <NavLink className="links" to="/conditions">
            Condition d'utilisations
          </NavLink>
        </div>
        <div className="block block-2">
          <p className="title">PLUS D'INFO SUR VAANCOIN</p>

          <NavLink className="links" to="/conditions">
            Conditions d’utilisation
          </NavLink>
          <NavLink className="links" to="/commentaires">
            Commentaires des clients
          </NavLink>
          <NavLink className="links" to="/faq">
            Faq
          </NavLink>
        </div>
        <div className="block block-3">
          <p className="title">COMPTE</p>
          <NavLink className="links" to="/profil">
            Profil
          </NavLink>
          <NavLink className="links" to="/history">
            Mes Echanges
          </NavLink>
          <NavLink className="links" to="/profil/verify">
            Vérification de Compte
          </NavLink>
        </div>
        <div className="block block-4">
          <p className="title">SUIVEZ NOUS SUR:</p>
          <div className="row">
            <a className="links" href="https://www.facebook.com/VaanCoin">
              <img src={Images.facebook} alt="facebook" />
            </a>
            <a className="links" href="https://www.instagram.com/vaancoin/">
              <img src={Images.instagram} alt="instagram" />
            </a>
            <a className="links" href="https://t.me/VaanCoin">
              <img src={Images.telegram} alt="telegram" />
            </a>
          </div>
        </div>
      </div>
      <p className="copyright">
        {" "}
        2022 Vaancoin. Tous droits réservés.
      </p>
    </footer>
  );
};

export default Footer;
