import React, { useContext, useEffect, useState } from "react";
import Select, { components } from "react-select";
import { NavLink } from "react-router-dom";
import { UidContext } from "./AppContext";
import { useDispatch, useSelector } from "react-redux";
import { isWebView, saveData, sendNotif } from "../Utils";
import { convertCurrency } from "../actions/currency.actions";
import SwitchSelector from "react-switch-selector";
import ActivityIndicator from "./ActivityIndicator";
import Button from "./Button";
import { Send } from "react-feather";
import Images from "../constantes/images";

const ExchengeForm = (props) => {
  const sender = document.querySelector("#sender");
  const receiver = document.querySelector("#receiver");

  const currencyData = useSelector((state) => state.currencyReducer);
  const dispatch = useDispatch();

  const [fromCurrency, setFromCurrency] = useState("");
  const [verdict, setVerdict] = useState("");
  const [toCurrency, setToCurrency] = useState("");
  const [result, setResult] = useState(null);
  const [error, setError] = useState(null);

  let isGiven = false;
  const [isSell, setIsSell] = useState(true);
  const [loading, setLoading] = useState(false);

  const { Option } = components;
  const uid = useContext(UidContext);
  const IconOption = (props) => (
    <Option {...props}>
      <img src={props.data.image} style={{ width: 20 }} alt={""} />
      {props.data.label}
    </Option>
  );

  //handl convert
  const handleConvert = async (value) => {
    dispatch(
      convertCurrency(
        fromCurrency,
        toCurrency,
        isGiven,
        value,
        isSell,
        setResult,
        setError,
        uid,
        setLoading
      )
    );
  };

  const handleExhange = async (e) => {
    e.preventDefault();
    if (Object.values(result).length > 5) {
      const dataIsSave = saveData("Exchange", result);
      if (dataIsSave) {
        window.location = "/transaction";
      } else {
        console.log("Une erreur s'est produit");
      }
      setError(null);
    } else setError("Désolé, pas d'échange avec ça");
  };
  let option1 = isSell
    ? currencyData
        .filter((currency) => currency.isCrypto)
        .map((currency) => ({
          value: currency.name,
          label: currency.name,
          image: currency.image
        }))
    : currencyData
        .filter((currency) => !currency.isCrypto)
        .map((currency) => ({
          value: currency.name,
          label: currency.name,
          image: currency.image
        }));

  let option2 = isSell
    ? currencyData
        .filter((currency) => !currency.isCrypto)
        .map((currency) => ({
          value: currency.name,
          label: currency.name,
          image: currency.image
        }))
    : currencyData
        .filter((currency) => currency.isCrypto)
        .map((currency) => ({
          value: currency.name,
          label: currency.name,
          image: currency.image
        }));

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      color: "black"
    }),

    singleValue: (provided, state) => {
      const opacity = state.isDisabled ? 0.5 : 1;
      const transition = "opacity 300ms";

      return { ...provided, opacity, transition };
    }
  };

  const switchOptions = [
    {
      label: "Vendre",
      value: true,
      selectedBackgroundColor: "#0c50b7"
    },
    {
      label: "Acheter",
      value: false,
      selectedBackgroundColor: "#0c50b7"
    }
  ];

  useEffect(() => {
    let res = isWebView();
    setVerdict(res);
  }, []);

  return (
    <form className="exchenge shadow" action="">
      <div className="switch-container">
        <SwitchSelector
          onChange={(value) => {
            setIsSell(value);
            setResult(null);
            sender.value = "";
            receiver.value = "";
          }}
          options={switchOptions}
          initialSelectedIndex={switchOptions.findIndex(
            ({ value }) => value === "Vendre"
          )}
          backgroundColor={"#D0D0D0"}
          fontColor={"#000"}
        />
      </div>
      <h1>Expace d'échange</h1>

      <div className="inputs">
        <div className="left">
          <p>Envoyer:</p>
          <Select
            options={option1}
            onChange={(e) => {
              setFromCurrency(e.value);
            }}
            components={{ Option: IconOption }}
            placeholder={"Choisir ici"}
            styles={customStyles}
          />
          <input
            className="val-input"
            id="sender"
            type="number"
            step="any"
            placeholder={`Le prix à envoyer ${isSell ? "en dollar" : ""} `}
            onChange={(e) => {
              isGiven = true;
              receiver.value = "";
              if (e.target.value >= 0) {
                handleConvert(e.target.value);
              } else sendNotif("Pas de ça ici", "error");
            }}
          />
        </div>
        <div className="right">
          <p>Recevoir:</p>
          <Select
            options={option2}
            onChange={(e) => {
              setToCurrency(e.value);
            }}
            components={{ Option: IconOption }}
            placeholder={"Choisir ici"}
            styles={customStyles}
          />
          <input
            className="val-input"
            id="receiver"
            type="number"
            step="any"
            placeholder={`Le prix à recevoir ${!isSell ? "en dollar" : ""} `}
            onChange={(e) => {
              isGiven = false;
              sender.value = "";
              if (e.target.value >= 0) {
                handleConvert(e.target.value);
              } else sendNotif("Pas de ça ici", "error");
            }}
          />
        </div>
      </div>
      <span className="error">{error}</span>
      {loading && <ActivityIndicator />}

      {result?.result ? (
        <div className="infos">
          <div className="info-1">
            <p>
              Taux de change : 1 USD ={" "}
              {isSell
                ? `${result?.fcurrency.sellRate} ${result?.tcurrency.devise}`
                : `${result?.tcurrency.buyRate} ${result?.fcurrency.devise}`}
            </p>
          </div>

          <p>
            Pour{" "}
            <span className="variable">
              {result?.toConvert}{" "}
              {result?.isGiven
                ? result?.fcurrency.devise
                : result?.tcurrency.devise}
            </span>{" "}
            vous {result?.isGiven ? "recevez" : "envoyez"} :{" "}
            <span className="variable">
              {result?.result}{" "}
              {result?.isGiven
                ? result?.tcurrency.devise
                : result?.fcurrency.devise}
            </span>
          </p>

          {uid ? null : (
            <p>
              Montant d'échange MAX:{" "}
              {result?.isGiven
                ? result?.fcurrency.maximal
                : result?.tcurrency.maximal}{" "}
              {result?.isGiven
                ? result?.fcurrency.devise
                : result?.tcurrency.devise}
              . Pour faire plus{" "}
              <NavLink to="/account">Créer un compte ici</NavLink>.
            </p>
          )}
        </div>
      ) : null}

      <Button
        value={"ÉCHANGER"}
        icon={<Send size={18} />}
        bg={"#0a47a2"}
        onClick={handleExhange}
      />

      {verdict === "android web" && (
        <a
          href="https://play.google.com/store/apps/details?id=com.vaancoin"
          target="_blank"
          rel="noreferrer"
        >
          <img
            src={Images.playstore}
            alt="playstore"
            width="155"
            height="50"
            style={{ marginTop: 10 }}
          />
        </a>
      )}
      {verdict === "ios web" && (
        <a href="#" target="_blank" rel="noreferrer">
          <img
            src={Images.appstore}
            alt="playstore"
            width="155"
            height="50"
            style={{ marginTop: 10 }}
          />
        </a>
      )}
    </form>
  );
};

export default ExchengeForm;
