import axios from "axios";
import { Resquest, sendNotif } from "../Utils";

export const GET_FAQ = "GET_FAQ";
export const UPDATE_FAQ = "UPDATE_FAQ";
export const DELETE_FAQ = "DELETE_FAQ";

export const getFaq = () => {
  return (dispatch) => {
    return axios
      .get(`${process.env.REACT_APP_SERVER}/api/faq`)
      .then((res) => {
        dispatch({ type: GET_FAQ, payload: res.data });
      })
      .catch((err) => console.log(err));
  };
};

export const updateFaq = (faqId, question, answer, setPending, close) => {
  return (dispatch) => {
    setPending(true);
    return Resquest.put(`${process.env.REACT_APP_SERVER}/api/faq/` + faqId, {
      question,
      answer
    })
      .then((res) => {
        if (res.data.success) {
          setPending(false);
          sendNotif(res.data.message, "success");
          close();
          dispatch({
            type: UPDATE_FAQ,
            payload: {
              faqId,
              question,
              answer
            }
          });
        } else {
          sendNotif(res.data.error, "error");
        }
      })
      .catch((err) => {
        setPending(false);
        console.log(err);
      });
  };
};

export const deleteFaq = (faqId, setError) => {
  return (dispatch) => {
    return Resquest.delete(`${process.env.REACT_APP_SERVER}/api/faq/${faqId}`)
      .then((res) => {
        if (res.data.success) {
          sendNotif(res.data.message, "success");
          dispatch({ type: DELETE_FAQ, payload: { faqId } });
        } else {
          sendNotif(res.data.error, "error");
        }
      })
      .catch((err) => console.log(err));
  };
};
