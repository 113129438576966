import { combineReducers } from "redux";
import userReducer from "./user.reducer";
import usersReducer from "./users.reducer";
import exchangesReducer from "./exchanges.reducer";
import ambassadorReducer from "./ambassador.reducer";
import currencyReducer from "./currency.reducer";
import commentReducer from "./comment.reducer";
import faqReducer from "./faq.reducer";
import errorReducer from "./error.reducer";
import bonusReducer from "./bonus.reducer";
import pubReducer from "./pub.reducer";

export default combineReducers({
  userReducer,
  usersReducer,
  exchangesReducer,
  ambassadorReducer,
  currencyReducer,
  commentReducer,
  faqReducer,
  errorReducer,
  bonusReducer,
  pubReducer
});
