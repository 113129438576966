import React, { useEffect, useState } from "react";
import axios from "axios";
import ActivityIndicator from "../components/ActivityIndicator";
import Loading from "../components/Loading";
import { NavLink } from "react-router-dom";
import { sendNotif } from "../Utils";
import Button from "../components/Button";
import { Eye, EyeOff, Lock, LogIn, Mail } from "react-feather";
import TextInput from "../components/TextInput";

const Login = () => {
  const [signPending, setSignPending] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isReveal, setIsReveal] = useState(false);

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    document.title = "Vaancoin - Connexion";
    document.description =
      "Connectez-vous avec votre compte afin de garder une trace de vos transactions.";
    window.scrollTo({ top: 0, behavior: "smooth" });
    setLoading(false);
  }, []);

  ///LOGIN
  const handleLogin = (e) => {
    e.preventDefault();
    setSignPending(true);

    const signInOptions = {
      method: "post",
      url: `${process.env.REACT_APP_SERVER}/api/user/signin`,
      withCredentials: true,
      data: {
        email,
        password
      }
    };

    axios(signInOptions)
      .then((res) => {
        if (!res.data.success) {
          setSignPending(false);
          sendNotif(res.data.message, "error");
        } else {
          setSignPending(false);
          window.location = "/profil";
        }
      })
      .catch((err) => {
        setSignPending(false);
        sendNotif("Une erreur s'est produite.", "error");
        console.log(err);
      });
  };

  /////////////////////
  if (loading) {
    return <Loading />;
  }
  return (
    <form action="" className="login-form" onSubmit={handleLogin}>
      <h1>Connexion</h1>
      <TextInput
        label="Email"
        type="email"
        name="email"
        lIcon={<Mail size={18} />}
        value={email}
        setValue={setEmail}
      />
      <label htmlFor="">
        <span>Mot de passe:</span>
        <span className="login-input">
          <i className="input-icon">
            <Lock size={18} />
          </i>
          <input
            type={isReveal ? "text" : "password"}
            name="password"
            onChange={(e) => setPassword(e.target.value)}
            value={password}
          />
          <i
            className="input-icon pointer"
            onClick={() => setIsReveal(!isReveal)}
          >
            {!isReveal ? <EyeOff size={18} /> : <Eye size={18} />}
          </i>
        </span>
      </label>
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "flex-end"
        }}
      >
        <NavLink
          to="/password-reset"
          style={{
            margin: 4,
            width: "max-content",
            padding: 6,
            color: "#4B4B4B"
          }}
        >
          Mot de passe oublié ?
        </NavLink>
      </div>
      {signPending ? (
        <ActivityIndicator />
      ) : (
        <Button
          value={"Se connecter"}
          type={"submit"}
          icon={<LogIn size={18} />}
          bg={"#0a47a2"}
        />
      )}
    </form>
  );
};

export default Login;
