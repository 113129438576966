import React, { useState } from "react";
import ActivityIndicator from "./ActivityIndicator";
import axios from "axios";
import { useDispatch } from "react-redux";
import { getFaq } from "../actions/faq.actions";
import { Resquest, sendNotif } from "../Utils";
import { CheckCircle } from "react-feather";
import Button from "./Button";

const AddFaq = ({ close }) => {
  const [question, setQuestion] = useState("");
  const [answer, setAnswer] = useState("");
  const [pending, setPending] = useState(false);

  const dispatch = useDispatch();

  const handleFaq = async (e) => {
    e.preventDefault();
    setPending(true);
    await Resquest.post(`${process.env.REACT_APP_SERVER}/api/faq`, {
      question,
      answer
    })
      .then((res) => {
        if (res.data.success) {
          sendNotif(res.data.message, "success");
          setPending(false);
          close();
          dispatch(getFaq());
        } else {
          setPending(false);
          sendNotif(res.data.error, "error");
        }
      })
      .catch((err) => {
        setPending(false);
        console.log(err);
      });
  };

  return (
    <form onSubmit={handleFaq} className="login-form">
      <span className="close" onClick={() => close()}>
        &#x2715;
      </span>
      <h1>Ajouter un Faq</h1>
      <label htmlFor="">
        <span>La question: </span>{" "}
        <input
          type="text"
          className="login-input"
          onChange={(e) => setQuestion(e.target.value)}
        />
      </label>
      <textarea
        className="comment-input"
        placeholder="La réponse"
        resize="none"
        onChange={(e) => {
          setAnswer(e.target.value);
        }}
      ></textarea>
      {pending ? (
        <ActivityIndicator />
      ) : (
        <Button
          value={"Mettre en ligne"}
          icon={<CheckCircle size={18} />}
          bg={"#1b37a7"}
        />
      )}
    </form>
  );
};

export default AddFaq;
