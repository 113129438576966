import React, { useState } from "react";
import ActivityIndicator from "./ActivityIndicator";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { getCurrency } from "../actions/currency.actions";
import {
  Resquest,
  sendNotif,
  sendNotification,
  sendNotificationPush
} from "../Utils";
import Button from "../components/Button";
import { CheckCircle } from "react-feather";
const AddMonnaie = ({ close }) => {
  const dispatch = useDispatch();
  const [pending, setPending] = useState(false);
  const usersData = useSelector((state) => state.usersReducer);
  const [name, setName] = useState("");
  const [image, setImage] = useState("");
  const [devise, setDevise] = useState("");
  const [sellRate, setSellRate] = useState("");
  const [buyRate, setBuyRate] = useState("");
  const [receiver, setReceiver] = useState("");
  const [reserve, setReserve] = useState("");
  const [minimum, setMinimum] = useState("");
  const [maximal, setMaximal] = useState("");
  const [toFixed, setToFixed] = useState("");
  const [isCrypto, setIsCrypto] = useState(false);

  const handleAddMonnaie = async (e) => {
    e.preventDefault();
    setPending(true);
    await Resquest.post(`${process.env.REACT_APP_SERVER}/api/currency`, {
      name,
      image,
      devise,
      sellRate,
      buyRate,
      receiver,
      reserve,
      minimum,
      maximal,
      toFixed,
      isCrypto
    })
      .then((res) => {
        if (res.data.success) {
          sendNotif(res.data.message, "success");
          setPending(false);
          close();
          dispatch(getCurrency());
          sendNotification(
            `Une nouvel ${name} monnaie vient d'être ajouter.`,
            usersData.filter((user) => !user.isAdmin).map((data) => data._id)
          );
          sendNotificationPush({
            title: "Ajout " + name,
            description: isCrypto
              ? `La monnaie ${name} vient d'être ajoutée. Taux achat: ${buyRate} FRANC CFA, Taux vente: ${sellRate} FRANC CFA`
              : `La monnaie ${name} vient d'être ajoutée.`
          });
        } else {
          setPending(false);
          sendNotif(res.data.message, "error");
        }
      })
      .catch((err) => {
        setPending(false);
        console.log(err);
      });
  };

  return (
    <form action="" className="login-form" onSubmit={handleAddMonnaie}>
      <span className="close" onClick={() => close()}>
        &#x2715;
      </span>
      <h1>Ajouter une monnaie</h1>
      <label htmlFor="">
        <span>Nom:</span>{" "}
        <input
          type="text"
          className="login-input"
          onChange={(e) => setName(e.target.value)}
        />
      </label>
      <label htmlFor="">
        <span>Reserve:</span>{" "}
        <input
          type="number"
          step="any"
          className="login-input"
          onChange={(e) => setReserve(e.target.value)}
        />
      </label>
      <label htmlFor="">
        <input
          type="checkbox"
          defaultChecked={isCrypto}
          onChange={() => setIsCrypto(!isCrypto)}
          className="checkbox"
        />
        <span>Est-ce de la cryptomonnaie ?</span>
      </label>
      {isCrypto ? (
        <>
          {" "}
          <label htmlFor="">
            {" "}
            <span>Taux pour vente(en Fcfa):</span>{" "}
            <input
              type="number"
              step="any"
              className="login-input"
              onChange={(e) => setSellRate(e.target.value)}
            />
          </label>
          <label htmlFor="">
            {" "}
            <span>Taux pour achat(en Fcfa):</span>{" "}
            <input
              type="number"
              step="any"
              className="login-input"
              onChange={(e) => setBuyRate(e.target.value)}
            />
          </label>{" "}
        </>
      ) : null}

      <label htmlFor="">
        {" "}
        <span>
          Combien de chiffres apres la virgule( Exemple pour 2, on 2.34 USD,
          pour 1 on a 2.3 USD):
        </span>{" "}
        <input
          type="number"
          className="login-input"
          onChange={(e) => setToFixed(e.target.value)}
        />
      </label>
      <label htmlFor="">
        {" "}
        <span>Montant minimum pour échange:</span>{" "}
        <input
          type="number"
          step="any"
          className="login-input"
          onChange={(e) => setMinimum(e.target.value)}
        />
      </label>
      <label htmlFor="">
        {" "}
        <span>Montant maximal à échanger si pas de compte:</span>{" "}
        <input
          type="number"
          step="any"
          className="login-input"
          onChange={(e) => setMaximal(e.target.value)}
        />
      </label>
      <label htmlFor="">
        {" "}
        <span>devise:</span>{" "}
        <input
          type="text"
          className="login-input"
          onChange={(e) => setDevise(e.target.value)}
        />
      </label>
      <label htmlFor="">
        {" "}
        <span>Coordonnée receveur:</span>{" "}
        <input
          type="text"
          className="login-input"
          onChange={(e) => setReceiver(e.target.value)}
        />
      </label>
      <label htmlFor="">
        <span>Lien image:</span>{" "}
        <input
          type="text"
          className="login-input"
          onChange={(e) => setImage(e.target.value)}
        />
      </label>

      {pending ? (
        <ActivityIndicator />
      ) : (
        <Button
          value={"Ajouter"}
          type="submit"
          icon={<CheckCircle size={18} />}
          bg={"#1b30a7"}
        />
      )}
    </form>
  );
};

export default AddMonnaie;
