import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Popup from "reactjs-popup";
import { getCookie, setCookie } from "../Utils";

const PopupPub = (props) => {
  const [show, setShow] = useState(false);
  const userData = useSelector((state) => state.userReducer);
  const pubData = useSelector((state) => state.pubReducer);

  useEffect(() => {
    let cookie = getCookie("popupShown");
    if (cookie) {
      setShow(false);
    } else setShow(true);
  }, []);

  return userData.isAdmin
    ? null
    : pubData.success && (
        <Popup closeOnDocumentClick={false} modal open={show}>
          {(close) => (
            <div className="modal">
              <button
                className="close"
                onClick={() => {
                  setShow(false);
                  close();
                  setCookie("popupShown", true, 0.0416667);
                }}
              >
                &times;
              </button>
              <img
                src={`data:image/png;base64,${pubData.image}`}
                alt="popup"
                style={{ cursor: pubData.lien ? "pointer" : "default" }}
                onClick={() =>
                  pubData.lien ? window.open(pubData.lien, "_blank") : null
                }
              />
            </div>
          )}
        </Popup>
      );
};

export default PopupPub;
