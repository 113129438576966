import React, { useState } from "react";
import Select, { components } from "react-select";
import { useSelector } from "react-redux";
import ActivityIndicator from "./ActivityIndicator";

import axios from "axios";
import { CheckCircle } from "react-feather";
import Button from "./Button";

const Cashout = ({ close }) => {
  const userData = useSelector((state) => state.userReducer);
  const currencyData = useSelector((state) => state.currencyReducer);

  const [success, setSuccess] = useState(null);
  const [error, setError] = useState(null);
  const [typeCurrency, setTypeCurrency] = useState();
  const [point, setPoint] = useState("");
  const [adresse, setAdresse] = useState("");
  const [sendPending, setSendPending] = useState(false);
  const { Option } = components;
  const IconOption = (props) => (
    <Option {...props}>
      <img src={props.data.image} style={{ width: 20 }} alt={""} />
      {props.data.label}
    </Option>
  );

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSendPending(true);
    await axios
      .post(
        `${process.env.REACT_APP_SERVER}/api/bonus/cashout/${userData._id}`,
        {
          typeCurrency,
          point,
          adresse
        }
      )
      .then((res) => {
        if (res.data.success) {
          setSendPending(false);
          setSuccess(res.data.message);
          setError(null);
          setTimeout(() => {
            setSuccess(null);
            window.location = "/profil/parrainage";
          }, 3000);
        } else {
          setSendPending(false);
          setError(res.data.error);
          setSuccess(null);
          setTimeout(() => {
            setError(null);
          }, 3000);
        }
      })
      .catch((err) => {
        console.log(err);
        setSendPending(false);
      });
  };

  return (
    <form className="login-form" action="" onSubmit={handleSubmit}>
      <span className="close" onClick={() => close()}>
        &#x2715;
      </span>
      <h1>Cashout</h1>
      <label htmlFor="">
        <span>Nombre de point à retirer:</span>
        <input
          type="number"
          className="login-input"
          onChange={(e) => setPoint(e.target.value)}
        />
      </label>
      <label htmlFor="">
        <p>Type de monnaie:</p>
        <Select
          options={currencyData?.map((currency) => ({
            value: currency.name,
            label: currency.name,
            image: currency.image
          }))}
          onChange={(e) => setTypeCurrency(e.value)}
          components={{ Option: IconOption }}
          placeholder={"Choisir ici"}
        />
      </label>
      <label htmlFor="">
        <span>Addresse:</span>
        <input
          type="text"
          className="login-input"
          onChange={(e) => setAdresse(e.target.value)}
        />
      </label>
      {sendPending ? (
        <ActivityIndicator />
      ) : (
        <Button
          type="submit"
          value={"Envoyer la demande"}
          icon={<CheckCircle size={18} />}
          bg={"#1b37a7"}
        />
      )}

      {success && <span className="success">{success}</span>}
      {error && <span className="error">{error}</span>}
    </form>
  );
};

export default Cashout;
