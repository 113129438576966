import React, { useEffect } from "react";

const About = () => {
  useEffect(() => {
    document.title = "Vaancoin - Tout savoir sur nous.";
    document.description =
      " Nous sommes basés à Abidjan Côte d'Ivoire dans la commune de Cocody - Deux Plateaux . Nous vous offrons nos services dans un temps minimum et à moindre coût.";
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  return (
    <div className="page-content">
      <div className="about-container">
        <h1>Qui sommes-nous ? </h1>
        <p>
          VaanCoin est une structure spécialisée dans les produits monétiques
          (carte prépayée ect) et vente de crypto-monnaie.
        </p>
        <p>
          Nous sommes basés à Abidjan Côte d'Ivoire dans la commune de Cocody -
          Deux Plateaux . Nous vous offrons nos services dans un temps minimum
          et à moindre coût.
        </p>
        <p>
          Nous vous vendons des Bitcoin, Perfect Money okpay et Payeer à des
          prix défiants toutes concurrences et à délai quasi-immédiat. Aussi
          nous vous proposons d'acheter vos Bitcoin, Perfect Money okpay et
          Payeer à des coûts très intérêssants.
        </p>
      </div>
    </div>
  );
};

export default About;
