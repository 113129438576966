import React, { useState } from "react";
import ActivityIndicator from "./ActivityIndicator";
import axios from "axios";
import { useDispatch } from "react-redux";
import { getAmbassador } from "../actions/ambassador.actions";

import { Resquest, sendNotif } from "../Utils";
import { CheckCircle } from "react-feather";
import Button from "./Button";

const AddAmbassador = ({ close }) => {
  const [name, setName] = useState("");
  const [codeParrain, setCodeParrain] = useState("");
  const [pending, setPending] = useState(false);

  const dispatch = useDispatch();

  const handleFaq = async (e) => {
    e.preventDefault();
    setPending(true);
    await Resquest.post(`${process.env.REACT_APP_SERVER}/api/ambassador`, {
      name,
      codeParrain
    })
      .then((res) => {
        if (res.data.success) {
          sendNotif(res.data.message, "success");
          setPending(false);
          close();
          dispatch(getAmbassador());
        } else {
          sendNotif(res.data.message, "error");
          setPending(false);
        }
      })
      .catch((err) => {
        setPending(false);
        console.log(err);
      });
  };

  return (
    <form onSubmit={handleFaq} className="login-form">
      <span className="close" onClick={() => close()}>
        &#x2715;
      </span>
      <h1>Ajouter un ambassadeur</h1>
      <label htmlFor="">
        <span>Nom: </span>{" "}
        <input
          type="text"
          className="login-input"
          onChange={(e) => setName(e.target.value)}
        />
      </label>
      <label htmlFor="">
        <span>Code parain: </span>{" "}
        <input
          type="text"
          className="login-input"
          onChange={(e) => setCodeParrain(e.target.value)}
        />
      </label>

      {pending ? (
        <ActivityIndicator />
      ) : (
        <Button
          value={"Ajouter"}
          icon={<CheckCircle size={18} />}
          bg={"#1b37a7"}
        />
      )}
    </form>
  );
};

export default AddAmbassador;
