import React, { useState } from "react";
import ActivityIndicator from "./ActivityIndicator";
import { useDispatch } from "react-redux";
import { updateFaq } from "../actions/faq.actions";
import { CheckCircle } from "react-feather";
import Button from "./Button";

const UpdateFaq = ({ close, data }) => {
  const [question, setQuestion] = useState("");
  const [answer, setAnswer] = useState("");
  const [pending, setPending] = useState(false);

  const dispatch = useDispatch();

  const handleUpdateFaq = async (e) => {
    e.preventDefault();
    setPending(true);
    dispatch(
      updateFaq(
        data._id,
        question || data.question,
        answer || data.answer,
        setPending,
        close
      )
    );
  };

  return (
    <form onSubmit={handleUpdateFaq} className="login-form">
      <span className="close" onClick={() => close()}>
        &#x2715;
      </span>
      <h1>Modifier un Faq</h1>
      <label htmlFor="">
        <span>La question: </span>{" "}
        <input
          type="text"
          className="val-input"
          defaultValue={data.question}
          onChange={(e) => setQuestion(e.target.value)}
        />
      </label>
      <textarea
        className="comment-input"
        placeholder="La réponse"
        resize="none"
        onChange={(e) => {
          setAnswer(e.target.value);
        }}
        defaultValue={data.answer}
      ></textarea>
      {pending ? (
        <ActivityIndicator />
      ) : (
        <Button
          type="submit"
          value={"Mettre à jour"}
          icon={<CheckCircle size={18} />}
          bg={"#1b37a7"}
        />
      )}
    </form>
  );
};

export default UpdateFaq;
