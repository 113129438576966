import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import Table from "../components/Table";

const Users = () => {
  const usersData = useSelector((state) => state.usersReducer);

  useEffect(() => {
    document.title = "Les utilisateurs";
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  const columns = [
    {
      name: "firstName",
      displayName: "Prénom(s)",
      inputFilterable: true,
      sortable: true
    },
    {
      name: "lastName",
      displayName: "Nom",
      inputFilterable: true,
      exactFilterable: true,
      sortable: true
    },
    {
      name: "email",
      displayName: "Email",
      inputFilterable: true,
      exactFilterable: true,
      sortable: true
    },
    {
      name: "phone",
      displayName: "Phone",
      inputFilterable: true,
      exactFilterable: true,
      sortable: true
    },
    {
      name: "referrers",
      displayName: "Total référé",
      inputFilterable: true,
      exactFilterable: true,
      sortable: true
    },
    {
      name: "bonus",
      displayName: "Bonus",
      inputFilterable: true,
      exactFilterable: true,
      sortable: true
    },
    {
      name: "isVerifiedCredentials",
      displayName: "Status compte",
      inputFilterable: true,
      exactFilterable: true,
      sortable: true
    }
  ];

  return (
    <div className="page-content">
      <div className="table-container">
        <h1>Les utilisateurs</h1>
        <Table
          columns={columns}
          data={usersData
            .filter((user) => !user.isAdmin)
            .map((data) => ({
              firstName: data.firstName,
              lastName: data.lastName,
              email: data.email,
              phone: data.phone,
              referrers: data.referrers.length,
              bonus: data.bonus,
              isVerifiedCredentials: data.isVerifiedCredentials
                ? "Vérifié"
                : "Non vérifié"
            }))}
        />
      </div>
    </div>
  );
};

export default Users;
