import React, { useState } from "react";
import ActivityIndicator from "./ActivityIndicator";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { getComments } from "../actions/comments.actions";
import { Resquest, sendNotif, sendNotification } from "../Utils";
import { CheckCircle } from "react-feather";
import Button from "./Button";

const AddComment = ({ close }) => {
  const [comment, setComment] = useState("");
  const [pending, setPending] = useState(false);

  const userData = useSelector((state) => state.userReducer);
  const usersData = useSelector((state) => state.usersReducer);

  const dispatch = useDispatch();

  const handleComment = async (e) => {
    e.preventDefault();
    setPending(true);
    await Resquest.post(`${process.env.REACT_APP_SERVER}/api/comment`, {
      userId: userData._id,
      comment
    })
      .then((res) => {
        if (res.data.success) {
          setPending(false);
          sendNotif(res.data.message, "success");
          sendNotification(
            `Un nouveau commentaire de la part de ${userData.firstName} ${userData.lastName} disant: "${comment}".`,
            usersData.filter((user) => user.isAdmin).map((data) => data._id)
          );
          close();
          dispatch(getComments());
        } else {
          setPending(false);
          sendNotif(res.data.error, "error");
        }
      })
      .catch((err) => {
        setPending(false);
        console.log(err);
      });
  };

  return (
    <form onSubmit={handleComment} className="login-form">
      <span className="close" onClick={close}>
        &#x2715;
      </span>
      <h2>Ajouter un commentaire</h2>
      <textarea
        className="comment-input"
        placeholder="Votre commentaire ici..."
        resize="none"
        onChange={(e) => {
          setComment(e.target.value);
        }}
      ></textarea>
      {pending ? (
        <ActivityIndicator />
      ) : (
        <Button
          value={"Publier"}
          icon={<CheckCircle size={18} />}
          bg={"#1b30a7"}
          type={"submit"}
        />
      )}
    </form>
  );
};

export default AddComment;
