import React, { useState, useEffect } from "react";
import { Archive } from "react-feather";
import { useSelector } from "react-redux";
import { fetchLocalData } from "../Utils";
import Button from "./Button";

const Status = () => {
  const usersData = useSelector((state) => state.usersReducer);
  const [online, setOnline] = useState(false);
  const [noData, setNoData] = useState(false);

  let data;

  useEffect(() => {
    if (
      usersData
        .filter((user) => user.isAdmin)
        .map((user) => user.isOnline)
        .includes(true)
    ) {
      setOnline(true);
    } else {
      setOnline(false);
    }
  }, [usersData]);

  useEffect(() => {
    fetchLocalData(data, setNoData);
  }, [data]);
  return (
    <span className="status-container">
      {noData ? null : (
        <Button
          value={"Historique"}
          className={"notif"}
          icon={<Archive size={18} />}
          bg={"#054531"}
          onClick={() => (window.location = "/transaction")}
        />
      )}
      <span
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between"
        }}
      >
        <span className={online ? "status online" : "status offline"}></span>
        <span
          className="status-text"
          style={{
            color: online ? "#249471" : "#8e1212"
          }}
        >
          {online ? "Opérateur en ligne" : "Opérateur hors ligne"}
        </span>
      </span>
    </span>
  );
};

export default Status;
