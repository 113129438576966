import React, { useContext, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { dateParser } from "../Utils";
import { UidContext } from "./AppContext";
import { deleteComment } from "../actions/comments.actions";
import Popup from "reactjs-popup";
import UpdateComment from "./UpdateComment";
import Images from "../constantes/images";
import { sendNotification } from "../Utils";
import { Edit2, Trash } from "react-feather";
import Button from "./Button";

const CommentCard = ({ data, isHome, socket }) => {
  const dispatch = useDispatch();
  const uid = useContext(UidContext);
  const userData = useSelector((state) => state.userReducer);
  const usersData = useSelector((state) => state.usersReducer);

  const [isReadMore, setIsReadMore] = useState(true);
  const [isAuthor, setIsAuthor] = useState(false);
  const [edit, setEdit] = useState(false);

  useEffect(() => {
    const checkAuthor = () => {
      if (data.userId && uid === data.userId._id) {
        setIsAuthor(true);
      }
    };
    checkAuthor();
  }, [data.userId, uid]);

  const toggleReadMore = () => {
    setIsReadMore(!isReadMore);
  };

  const handleDelete = () => {
    dispatch(deleteComment(data._id));
    sendNotification(
      `${userData.firstName} ${userData.lastName} a supprimé son commentaire.`,
      usersData.filter((user) => user.isAdmin).map((data) => data._id)
    );
  };

  return (
    <div className="slider-item">
      <div className="animation-card_content">
        <div className="flex">
          <h4 className="animation-card_content_title title-2">
            {!data.userId
              ? `User ${Date.now().toString(36)}`
              : `${data.userId.firstName} ${data.userId.lastName}`}
          </h4>
          {!isHome && userData.isAdmin ? (
            <img
              src={Images.trash}
              alt=""
              className="icon"
              onClick={() => {
                if (window.confirm("Voulez-vous supprimer ce commentaire ?")) {
                  handleDelete();
                }
              }}
            />
          ) : null}
        </div>

        <p className="animation-card_content_description p-2">
          {isReadMore ? data.comment.slice(0, 50) : data.comment}{" "}
          {data.comment.length > 50 && (
            <span
              className={
                isReadMore
                  ? "animation-card_content_see_more success"
                  : "animation-card_content_see_more error"
              }
              onClick={toggleReadMore}
            >
              {isReadMore ? "...voir plus" : " voir moins"}
            </span>
          )}
        </p>
        <p className="animation-card_content_see_more">
          publié le: {dateParser(data.createdAt)}
        </p>
        {!isHome && isAuthor && edit === false && (
          <div className="row">
            <Popup
              modal={true}
              nested
              trigger={
                <Button
                  value={"Modifier"}
                  icon={<Edit2 size={18} />}
                  bg={"#09b72cba"}
                  onClick={() => setEdit(!edit)}
                />
              }
            >
              {(close) => <UpdateComment data={data} close={close} />}
            </Popup>
            <Button
              value={"Supprimer"}
              icon={<Trash size={18} />}
              bg={"#b70909ba"}
              onClick={() => {
                if (window.confirm("Voulez-vous supprimer ce commentaire ?")) {
                  handleDelete();
                }
              }}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default CommentCard;
