import React, { useState } from "react";
import ActivityIndicator from "./ActivityIndicator";
import { useDispatch, useSelector } from "react-redux";
import { updateCurrency } from "../actions/currency.actions";
import Button from "../components/Button";
import { CheckCircle } from "react-feather";

const UpdateMonnaie = ({ close, data }) => {
  const dispatch = useDispatch();
  const [pending, setPending] = useState(false);
  const userData = useSelector((state) => state.userReducer);
  const usersData = useSelector((state) => state.usersReducer);

  const [name, setName] = useState("");
  const [image, setImage] = useState("");
  const [devise, setDevise] = useState("");
  const [sellRate, setSellRate] = useState("");
  const [buyRate, setBuyRate] = useState("");
  const [receiver, setReceiver] = useState("");
  const [reserve, setReserve] = useState("");
  const [minimum, setMinimum] = useState("");
  const [maximal, setMaximal] = useState("");
  const [toFixed, setToFixed] = useState("");
  const [isCrypto, setIsCrypto] = useState(data.isCrypto);

  const handleUpdate = (e) => {
    e.preventDefault();
    setPending(true);
    dispatch(
      updateCurrency(
        data._id,
        name || data.name,
        image || data.image,
        devise || data.devise,
        sellRate || data.sellRate,
        buyRate || data.buyRate,
        receiver || data.receiver,
        reserve || data.reserve,
        minimum || data.minimum,
        maximal || data.maximal,
        toFixed || data.toFixed,
        isCrypto || data.isCrypto,
        usersData.filter((user) => !user.isAdmin).map((data) => data._id)
      )
    );
    setPending(false);
    close();
  };

  return (
    <form action="" className="login-form" onSubmit={handleUpdate}>
      <span className="close" onClick={close}>
        &#x2715;
      </span>
      <h1>Modifier une monnaie</h1>
      <div className="content">
        <label htmlFor="">
          <span>Nom:</span>{" "}
          <input
            type="text"
            className="val-input"
            defaultValue={data.name}
            onChange={(e) => setName(e.target.value)}
          />
        </label>
        <label htmlFor="">
          <span>Reserve:</span>{" "}
          <input
            type="number"
            className="val-input"
            defaultValue={data.reserve}
            onChange={(e) => setReserve(e.target.value)}
          />
        </label>
        <label htmlFor="">
          <input
            type="checkbox"
            defaultChecked={data.isCrypto}
            onChange={() => setIsCrypto(!isCrypto)}
            className="checkbox"
          />
          <span>Est-ce de la cryptomonnaie ?</span>
        </label>

        {isCrypto ? (
          <>
            {" "}
            <label htmlFor="">
              {" "}
              <span>Taux pour vente(en Fcfa):</span>{" "}
              <input
                type="number"
                step="any"
                className="val-input"
                defaultValue={data.sellRate}
                onChange={(e) => setSellRate(e.target.value)}
              />
            </label>
            <label htmlFor="">
              {" "}
              <span>Taux pour achat(en Fcfa):</span>{" "}
              <input
                type="number"
                step="any"
                className="val-input"
                defaultValue={data.buyRate}
                onChange={(e) => setBuyRate(e.target.value)}
              />
            </label>{" "}
          </>
        ) : null}
        <label htmlFor="">
          {" "}
          <span>
            {" "}
            Combien de chiffres apres la virgule( Exemple pour 2, on 2.34 USD,
            pour 1 on a 2.3 USD):
          </span>{" "}
          <input
            type="number"
            step="any"
            className="val-input"
            defaultValue={data.toFixed}
            onChange={(e) => setToFixed(e.target.value)}
          />
        </label>
        <label htmlFor="">
          {" "}
          <span>Montant minimum pour échange:</span>{" "}
          <input
            type="number"
            step="any"
            className="val-input"
            defaultValue={data.minimum}
            onChange={(e) => setMinimum(e.target.value)}
          />
        </label>
        <label htmlFor="">
          {" "}
          <span>Montant maximal à échanger si pas de compte:</span>{" "}
          <input
            type="number"
            className="val-input"
            step="any"
            defaultValue={data.maximal}
            onChange={(e) => setMaximal(e.target.value)}
          />
        </label>
        <label htmlFor="">
          {" "}
          <span>devise:</span>{" "}
          <input
            type="text"
            className="val-input"
            defaultValue={data.devise}
            onChange={(e) => setDevise(e.target.value)}
          />
        </label>
        <label htmlFor="">
          {" "}
          <span>Coordonnée receveur:</span>{" "}
          <input
            type="text"
            className="val-input"
            defaultValue={data.receiver}
            onChange={(e) => setReceiver(e.target.value)}
          />
        </label>
        <label htmlFor="">
          <span>Lien image:</span>{" "}
          <input
            type="text"
            className="val-input"
            defaultValue={data.image}
            onChange={(e) => setImage(e.target.value)}
          />
        </label>

        {pending ? (
          <ActivityIndicator />
        ) : (
          <Button
            value={"Modifier"}
            type="submit"
            icon={<CheckCircle size={18} />}
            bg={"#1b30a7"}
          />
        )}
      </div>
    </form>
  );
};

export default UpdateMonnaie;
