import Cookies from "js-cookie";
import axios from "axios";
import moment from "moment";
import "moment/locale/fr";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import io from "socket.io-client";
import Images from "./constantes/images";
import { useDispatch, useSelector } from "react-redux";

moment.locale("fr");

////COOKIES
export const setCookie = (name, value, days) => {
  try {
    Cookies.set(name, JSON.stringify(value), { expires: days });
  } catch (err) {
    Cookies.log(err);
  }
};

export const getCookie = (name) => {
  return Cookies.get(name);
};

export const removeCookie = (key) => {
  if (window !== "undefined") {
    Cookies.remove(key, { expires: 1 });
  }
};
////////////////////

///LOCAL STORAGE
export const saveData = (name, value) => {
  try {
    localStorage.setItem(name, JSON.stringify(value));
    return true;
  } catch (err) {
    console.log(err);
    return false;
  }
};
//get data
export const getData = (name) => {
  return localStorage.getItem(name);
};
export const deleteData = (name) => {
  localStorage.removeItem(name);
  return true;
};
///////
export const fetchLocalData = async (
  data,
  setNoData,
  setTransaction,
  setLoad,
  setResumData
) => {
  data = getData("Exchange");
  if (data && Object.keys(data).length !== 0) {
    setTransaction && setTransaction(JSON.parse(data));
    setLoad && setLoad(false);
    setNoData(false);
    setResumData && setResumData(data);
  } else {
    setNoData(true);
    setLoad && setLoad(false);
    setResumData && setResumData();
  }
};
///

export const handleLogout = async (e) => {
  e.preventDefault();
  await Resquest.get(`${process.env.REACT_APP_SERVER}/api/user/signout`)
    .then((res) => {
      if (res.data.success) {
        removeCookie("jwt");
        window.location = "/";
        sendNotif(res.data.message, "success");
      } else {
        sendNotif(res.data.message, "error");
      }
    })
    .catch((err) => console.log(err));
};

export const dateParser = (createdAt) => {
  var date = moment(createdAt).format("DD MMMM YYYY à HH:mm");

  return date.toString();
};
export const dateParser2 = (date) => {
  var date2 = moment(date).format("DD MMMM YYYY");

  return date2.toString();
};

export const datesArray = (from, to, interval) => {
  let ret = [];
  const fromDate = moment(from, "DD MMMM YYYY");
  const toDate = moment(to, "DD MMMM YYYY");
  let date = fromDate.add(interval, "days");
  while (toDate > date) {
    ret.push(date.format("DD MMMM YYYY"));
    date = moment(date).add(interval, "days");
  }
  return ret;
};

export const isEmpty = (value) => {
  return (
    value === undefined ||
    value === null ||
    (typeof value === "object" && Object.keys(value).length === 0) ||
    (typeof value === "string" && value.trim().length === 0)
  );
};

export const getNewTransactionCount = async (setNewTransaction) => {
  await axios
    .get(`${process.env.REACT_APP_SERVER}/api/exchange/`)
    .then((res) => {
      if (res.data.success) {
        setNewTransaction(
          res.data.exchange.filter((transactions) => !transactions.isValidate)
            .length
        );
      } else {
        setNewTransaction(null);
      }
    })
    .catch((err) => {
      setNewTransaction(null);
      console.log(err);
    });
};

export const getNewVerificationCount = async (setNewVerify) => {
  await axios
    .get(`${process.env.REACT_APP_SERVER}/api/user/userverify/all`)
    .then((res) => {
      if (res.data.success) {
        setNewVerify(res.data.verifyList.length);
      } else {
        setNewVerify(null);
      }
    })
    .catch((err) => {
      setNewVerify(null);
    });
};
export const getNewBonusCount = async (setNewBonus) => {
  await axios
    .get(`${process.env.REACT_APP_SERVER}/api/bonus/cashout`)
    .then((res) => {
      if (res.data.success) {
        setNewBonus(res.data.bonus.filter((bonus) => !bonus.isValidate).length);
      } else {
        setNewBonus(null);
      }
    })
    .catch((err) => {
      setNewBonus(null);
      console.log(err);
    });
};

export const sendNotification = async (message, usersId) => {
  await Resquest.post(`${process.env.REACT_APP_SERVER}/send-notification`, {
    message,
    usersId
  });
};

export const NotificationReceive = (uid) => {
  let socket = io(`${process.env.REACT_APP_SERVER}`, {
    transports: ["websocket", "polling", "flashsocket"]
  });
  socket.on("notification", function (details) {
    console.log(details);
    for (let i = 0; i < details.usersId.length; i++) {
      if (uid == details.usersId[i]) {
        let audio = new Audio(Images.sound);
        audio.loop = false;
        audio.play();
        toast.info(details.message, {
          position: "bottom-center",
          autoClose: false,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined
        });
      }
    }
  });
};

///

export const sendNotif = (message, type, id) => {
  toast(message, {
    type: type,
    toastId: id,
    position: "bottom-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined
  });
};

/////

export const isWebView = () => {
  let verdict = null;
  var standalone = window.navigator.standalone,
    userAgent = window.navigator.userAgent.toLowerCase(),
    safari = /safari/.test(userAgent),
    ios = /iphone|ipod|ipad/.test(userAgent);

  if (ios) {
    if (!standalone && safari) {
      verdict = "ios web";
    } else if (!standalone && !safari) {
      verdict = "ios webview";
    }
  } else {
    if (userAgent.includes("wv")) {
      verdict = "android webview";
    } else {
      verdict = "android web";
    }
  }
  return verdict;
};

//

export const Resquest = axios.create({
  withCredentials: true
});

export const sendNotificationPush = async ({
  title,
  description,
  image,
  setPending,
  close
}) => {
  setPending && setPending(true);
  await Resquest.post(`${process.env.REACT_APP_SERVER}/api/notification/push`, {
    title,
    description,
    image
  })
    .then((res) => {
      if (res.data.success) {
        sendNotif(res.data.message, "success");
        setPending && setPending(false);
        close && close();
      } else {
        setPending && setPending(false);
        sendNotif(res.data.message, "error");
      }
    })
    .catch((err) => {
      sendNotif("Une erreur s'est produite", "error");
      console.log(err);
    });
};
