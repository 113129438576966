import React from "react";
import Images from "../constantes/images";

const Whatsapp = (dataIsThere) => {
  return (
    // eslint-disable-next-line react/jsx-no-target-blank
    <a
      href={`https://wa.me/${process.env.REACT_APP_NUM}`}
      target="_blank"
      className="whatsapp"
    >
      <img src={Images.whatsapp} alt="whatsapp" className="icon" />{" "}
    </a>
  );
};

export default Whatsapp;
