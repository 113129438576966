import axios from "axios";
import {
  Resquest,
  sendNotif,
  sendNotification,
  sendNotificationPush
} from "../Utils";

export const GET_CURRENCY = "GET_CURRENCY";
export const UPDATE_CURRENCY = "UPDATE_CURRENCY";
export const DELETE_CURRENCY = "DELETE_CURRENCY";
export const HANDLE_CONVERT = "HANDLE_CONVERT";

export const getCurrency = () => {
  return (dispatch) => {
    return axios
      .get(`${process.env.REACT_APP_SERVER}/api/currency`)
      .then((res) => {
        dispatch({ type: GET_CURRENCY, payload: res.data });
      })
      .catch((err) => console.log(err));
  };
};

export const updateCurrency = (
  currencyId,
  name,
  image,
  devise,
  sellRate,
  buyRate,
  receiver,
  reserve,
  minimum,
  maximal,
  toFixed,
  isCrypto,
  usersId
) => {
  return (dispatch) => {
    return Resquest.put(
      `${process.env.REACT_APP_SERVER}/api/currency/` + currencyId,
      {
        name,
        image,
        devise,
        sellRate,
        buyRate,
        receiver,
        reserve,
        minimum,
        maximal,
        toFixed,
        isCrypto
      }
    )
      .then((res) => {
        sendNotif(res.data.message, "success");
        sendNotification(
          isCrypto
            ? `La monnaie ${name} vient d'être mis à jour. Taux achat: ${buyRate} FRANC CFA, Taux vente: ${sellRate} FRANC CFA`
            : `La monnaie ${name} vient d'être mis à jour.`,
          usersId
        );
        sendNotificationPush({
          title: "Mis à jour pour " + name,
          description: isCrypto
            ? `La monnaie ${name} vient d'être mis à jour. Taux achat: ${buyRate} FRANC CFA, Taux vente: ${sellRate} FRANC CFA`
            : `La monnaie ${name} vient d'être mis à jour.`
        });

        dispatch({
          type: UPDATE_CURRENCY,
          payload: {
            currencyId,
            name,
            image,
            devise,
            sellRate,
            buyRate,
            receiver,
            reserve,
            minimum,
            maximal,
            toFixed,
            isCrypto
          }
        });
      })
      .catch((err) => console.log(err));
  };
};

export const deleteCurrency = (currencyId) => {
  return (dispatch) => {
    return Resquest.delete(
      `${process.env.REACT_APP_SERVER}/api/currency/${currencyId}`
    )
      .then((res) => {
        if (res.data.success) {
          sendNotif(res.data.message, "success");
          dispatch({ type: DELETE_CURRENCY, payload: { currencyId } });
        } else {
          sendNotif(res.data.error, "error");
        }
      })
      .catch((err) => {
        console.log(err);
        sendNotif("Une erreur s'est produite.", "error");
      });
  };
};

export const convertCurrency = (
  fromCurrency,
  toCurrency,
  isGiven,
  toConvert,
  isSell,
  setResult,
  setError,
  uid,
  setLoading
) => {
  return (dispatch) => {
    setLoading(true);
    return axios({
      method: "post",
      url: `${process.env.REACT_APP_SERVER}/api/currency/convert`,
      data: {
        fromCurrency,
        toCurrency,
        isGiven,
        toConvert,
        isSell,
        uid
      }
    })
      .then((res) => {
        if (res.data.success) {
          setLoading(false);
          setError(null);
          setResult(res.data);
        } else {
          setLoading(false);
          setResult(null);
          setError(res.data.message);
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };
};
