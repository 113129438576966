import React, { useState, useEffect } from "react";
import SwitchSelector from "react-switch-selector";
import { Login, Register } from "../screens";

const Account = ({ toScreen }) => {
  const [screen, setScreen] = useState("");

  useEffect(() => {
    setScreen(toScreen);
  }, [toScreen]);
  const switchOptions = [
    {
      label: "Connexion",
      value: "Connexion",
      selectedBackgroundColor: "#0c50b7"
    },
    {
      label: "Inscription",
      value: "Inscription",
      selectedBackgroundColor: "#0c50b7"
    }
  ];

  return (
    <div className="page-content">
      <div className="login-container">
        <div className="switch-container">
          <SwitchSelector
            onChange={(value) => {
              setScreen(value);
            }}
            options={switchOptions}
            initialSelectedIndex={switchOptions.findIndex(
              ({ value }) => value === toScreen
            )}
            backgroundColor={"#D0D0D0"}
            fontColor={"#000"}
          />
        </div>
        {screen === "Connexion" ? <Login /> : <Register />}
      </div>
    </div>
  );
};

export default Account;
