import React from "react";
import Images from "../constantes/images";
import ActivityIndicator from "./ActivityIndicator";

const Loading = () => {
  return (
    <div className="page-template">
      <div className="load-overlay">
        <img src={Images.logo} alt="logo" />
        <ActivityIndicator />
      </div>
    </div>
  );
};

export default Loading;
