import logo from "../assets/icons/logo.png";
import menu from "../assets/icons/menu.png";
import close from "../assets/icons/close.png";
import load from "../assets/icons/load.gif";

import facebook from "../assets/icons/facebook.png";
import instagram from "../assets/icons/instagram.png";
import telegram from "../assets/icons/telegram.png";
import whatsapp from "../assets/icons/whatsapp.png";
import playstore from "../assets/icons/playstore.png";
import appstore from "../assets/icons/appstore.png";

import historyExchange from "../assets/icons/historyExchange.png";
import user from "../assets/icons/user.png";

import exchange from "../assets/icons/exchange.png";
import trash from "../assets/icons/trash.png";
import notif from "../assets/icons/notification.png";

import sound from "../assets/audios/notif.wav";

const Images = {
  logo,
  close,
  menu,
  load,

  facebook,
  instagram,
  telegram,
  whatsapp,

  historyExchange,
  user,
  exchange,
  trash,
  sound,
  notif,
  playstore,
  appstore
};
export default Images;
