import React, { useState } from "react";
import { CheckCircle, XCircle } from "react-feather";
import { useDispatch } from "react-redux";
import {
  deleteTransaction,
  validateTransaction
} from "../actions/user.actions";
import { dateParser } from "../Utils";
import ActivityIndicator from "./ActivityIndicator";
import Button from "./Button";

const TransactionCard = ({ transaction, handleTransactionsFetch }) => {
  const [pending, setPending] = useState(false);

  const dispatch = useDispatch();

  const handleValidate = () => {
    dispatch(
      validateTransaction(transaction, setPending, handleTransactionsFetch)
    );
  };

  const handleDelete = () => {
    const agree = window.confirm(
      "Voulez-vous vraiment annuler et supprimer la transaction ?"
    );
    if (agree) {
      dispatch(
        deleteTransaction(transaction, setPending, handleTransactionsFetch)
      );
    }
  };

  return (
    <div className="card2">
      {transaction.userId ? (
        <p>
          Utilisateur:{" "}
          <span className="variable">
            {transaction.userId?.firstName} {transaction.userId?.lastName}
          </span>
        </p>
      ) : (
        <span>Utilisateur pas inscrit</span>
      )}

      {transaction.userId &&
        transaction.userId?.parrain &&
        transaction.userId?.firstTransaction && (
          <p>
            Code parrain ou ambassadeur :{" "}
            <span className="variable">{transaction.userId?.parrain}</span>
          </p>
        )}

      <p>
        id: <span className="variable">{transaction._id}</span>
      </p>
      <p>
        Montant envoyé:{" "}
        <span className="variable">
          {transaction.resume.isGiven
            ? transaction.resume.toConvert
            : transaction.resume.result}{" "}
          {transaction.resume.fcurrency.devise}{" "}
          {transaction.resume.fcurrency.name}
        </span>
      </p>
      <p>
        Doit recevoir:{" "}
        <span className="variable">
          {" "}
          {transaction.resume.isGiven
            ? transaction.resume.result
            : transaction.resume.toConvert}{" "}
          {transaction.resume.tcurrency.devise}{" "}
          {transaction.resume.tcurrency.name}
        </span>
      </p>
      <p>
        {" "}
        au: <span className="variable"> {transaction.adresse}</span>
      </p>
      <p>
        référence: <span className="variable">{transaction.ref}</span>
      </p>
      <span>
        status:{" "}
        {transaction.isValidate ? (
          <span className="success">validé</span>
        ) : (
          <span className="error">En attente</span>
        )}
      </span>
      <span className="small">Date: {dateParser(transaction.createdAt)}</span>
      {transaction.isValidate ? null : pending ? (
        <ActivityIndicator />
      ) : (
        <div style={{ display: "flex" }}>
          <Button
            value={"Valider la transaction"}
            icon={<CheckCircle size={18} />}
            bg={"#18aa6d"}
            onClick={handleValidate}
          />

          <Button
            value={"Annuler la transaction"}
            icon={<XCircle size={18} />}
            bg={"#db2947"}
            onClick={handleDelete}
          />
        </div>
      )}
    </div>
  );
};

export default TransactionCard;
