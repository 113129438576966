import React from "react";

const TextInput = ({ label, type, name, lIcon, rIcon, value, setValue }) => {
  return (
    <label htmlFor="">
      <span>{label}:</span>
      <span className="login-input">
        {lIcon && <i className="input-icon">{lIcon}</i>}
        <input
          type={type}
          name={name}
          onChange={(e) => setValue(e.target.value)}
          value={value}
        />
      </span>
      {rIcon && <i className="input-icon">{rIcon}</i>}
    </label>
  );
};

export default TextInput;
