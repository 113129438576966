/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import axios from "axios";
import ActivityIndicator from "../components/ActivityIndicator";
import TransactionCard from "../components/TransactionCard";
import ToggleSwitch from "../components/ToggleSwitch";
import Pagination from "../components/Pagination";

const Transactions = () => {
  const [dataLoad, setDataLoad] = useState(false);
  const [error, setError] = useState(null);

  const [currentPage, setCurrentPage] = useState(1);

  const [filterLists, setFilterLists] = useState([]);

  const [items, setItems] = useState([]);

  const [isSunShining, setIsSunShining] = useState(false);

  const handleTransactionsFetch = async () => {
    setDataLoad(true);
    await axios
      .get(`${process.env.REACT_APP_SERVER}/api/exchange/`)
      .then((res) => {
        if (res.data.success) {
          if (isSunShining)
            setFilterLists(
              res.data.exchange.filter(
                (transactions) => transactions.isValidate
              )
            );
          else
            setFilterLists(
              res.data.exchange.filter(
                (transactions) => !transactions.isValidate
              )
            );
          setError(null);
        } else {
          setError(res.data.message);
        }
      })
      .catch((err) => {
        setFilterLists([]);
        console.log(err);
      });
    setDataLoad(false);
  };

  useEffect(() => {
    document.title = "Demandes de transactions";
    window.scrollTo({ top: 0, behavior: "smooth" });
    handleTransactionsFetch();
  }, [isSunShining]);

  const onChange = (newValue) => {
    setCurrentPage(1);
    setIsSunShining(newValue);
  };

  return (
    <div className="page-content">
      <div className="transaction-container">
        <input
          type="button"
          className="refresh"
          value="↻"
          onClick={handleTransactionsFetch}
        />
        <ToggleSwitch
          name="status"
          checked={isSunShining}
          onChange={onChange}
        />
      </div>

      {dataLoad ? (
        <div className="refresh-container">
          <ActivityIndicator />
        </div>
      ) : (
        <div className="historique-container">
          {error ? (
            <div className="card error">{error}</div>
          ) : items.length > 0 ? (
            items.map((transaction) => {
              return (
                <TransactionCard
                  transaction={transaction}
                  handleTransactionsFetch={handleTransactionsFetch}
                  key={transaction._id}
                />
              );
            })
          ) : (
            <div className="card error">Pas de demande.</div>
          )}
        </div>
      )}
      <Pagination
        data={filterLists}
        totalCount={filterLists.length}
        setItems={setItems}
        className="pagination-bar"
        currentPage={currentPage}
        pageSize={4}
        onPageChange={(page) => setCurrentPage(page)}
      />
    </div>
  );
};

export default Transactions;
