import axios from "axios";

export const GET_EXCHANGE = "GET_EXCHANGE";

export const getExchanges = () => {
  return (dispatch) => {
    return axios
      .get(`${process.env.REACT_APP_SERVER}/api/exchange`)
      .then((res) => {
        dispatch({ type: GET_EXCHANGE, payload: res.data.exchange });
      })
      .catch((err) => console.log(err));
  };
};
