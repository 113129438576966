import React, { useEffect, useState } from "react";
import ActivityIndicator from "../components/ActivityIndicator";
import { _updateProfile } from "../actions/user.actions";
import { useDispatch, useSelector } from "react-redux";
import { CheckCircle } from "react-feather";
import Button from "../components/Button";

const UpdateProfile = ({ close }) => {
  const [pending, setPending] = useState(false);

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");

  const dispatch = useDispatch();
  const userData = useSelector((state) => state.userReducer);

  useEffect(() => {
    document.title = "Modifier mon profil";
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  ///Update profile
  const handleUpdate = (e) => {
    e.preventDefault();
    dispatch(
      _updateProfile(
        firstName || userData.firstName,
        lastName || userData.lastName,
        email || userData.email,
        phone || userData.phone,
        setPending,
        close
      )
    );
  };
  /////

  return (
    <form action="" className="login-form" onSubmit={handleUpdate}>
      <h1>Mise à jour de mon profil</h1>
      <span className="close" onClick={() => close()}>
        &#x2715;
      </span>
      <label htmlFor="">
        <span>Nom:</span>{" "}
        <input
          type="text"
          className="login-input"
          onChange={(e) => setLastName(e.target.value)}
          defaultValue={userData.lastName}
        />
      </label>
      <label htmlFor="">
        <span>Prénom(s):</span>{" "}
        <input
          type="text"
          className="login-input"
          onChange={(e) => setFirstName(e.target.value)}
          defaultValue={userData.firstName}
        />
      </label>
      <label htmlFor="">
        {" "}
        <span>Email:</span>{" "}
        <input
          type="email"
          className="login-input"
          onChange={(e) => setEmail(e.target.value)}
          defaultValue={userData.email}
        />
      </label>
      <label htmlFor="">
        {" "}
        <span>Numéro de téléphone:</span>{" "}
        <input
          type="tel"
          className="login-input"
          onChange={(e) => setPhone(e.target.value)}
          defaultValue={userData.phone}
        />
      </label>

      {pending ? (
        <ActivityIndicator />
      ) : (
        <Button
          type="submit"
          value={"Valider les modifications"}
          icon={<CheckCircle size={18} />}
          bg={"#1b37a7"}
        />
      )}
    </form>
  );
};

export default UpdateProfile;
